// Global Imports 
import React from "react"

// Other File Imports

// Design Imports 
import {
    LinearProgress,
} from "@material-ui/core";


const Progress = (props) => {
    const moduleProgress = props.progress;
    return (
        <>
                <div className="w-full flex flex-col">


                    {/* Completion Progress */}
                    <div className="flex flex-row items-center justify-between text-lg font-semibold">
                        <p>
                            Complete:
                            {(
                                moduleProgress.totalProgress
                            ).toFixed(0)}
                            %
                        </p>
                        <p>&nbsp;</p>
                    </div>
                    <LinearProgress
                        variant="determinate"
                        value={moduleProgress.totalProgress}
                    />


                </div>
        </>
    );
}

export default Progress;