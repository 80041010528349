// Global Imports 
import React from "react";
import { Link } from "react-router-dom";

// Design Imports 
import { Grid } from "@material-ui/core";

// Data Imports 
import TestimonialData from "./TestimonialData";

const Testimonials = () => {
    return (
        <>
            <Grid container className='land'>
                <Grid item md={12} align='center' className='land-first'>
                    <p style={{ fontSize: "28px", marginTop: "40px", marginBottom: "40px" }}>Below are a few <b style={{ color: '#b32800' }}>snapshots</b> of people ranging from Junior, Mid & even HR Influencers who have attended the Workshops.</p>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Grid item md={5}>
                        {
                            TestimonialData.map((data, i) => {
                                if (i < 4) {
                                    return (
                                        <Link
                                            to={{ pathname: data.link }}
                                            target="_blank"
                                            key={i}
                                        >
                                            <img width={data.width} height={data.height} alt={data.alt} src={data.image} />
                                        </Link>
                                    );
                                } else {
                                    return (<div key={i}></div>);
                                }
                            })
                        }
                    </Grid>
                    <Grid item md={5}>
                        {
                            TestimonialData.map((data, i) => {
                                if (i >= 4) {
                                    return (
                                        <Link
                                            to={{ pathname: data.link }}
                                            target="_blank"
                                            key={i}
                                        >
                                            <img width={data.width} height={data.height} alt={data.alt} src={data.image} />
                                        </Link>
                                    );
                                } else {
                                    return (<div key={i}></div>);
                                }
                            })
                        }
                    </Grid>
                </div>
            </Grid>
        </>
    );
}

export default Testimonials;