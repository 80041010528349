// Global Imports 
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Other Components
import PreviewCard from "./PreviewCard";
import PreviewCardLoading from "./PreviewCardLoading";

const Pagination = (props) => {

    const [pageNumber, setPageNumber] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [courses, setCourses] = useState([]);
    const [coursesLoading, setCoursesLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getCourses();
    }, [pageNumber]);

    const getCourses = async () => {
        try {
            setCoursesLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/list?perPage=${perPage + 1}&pageNumber=${pageNumber}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const data = (await response.json());
            if(data.apiStatus && data.apiStatus != "SUCCESS" && data.apiStatus != "Success" && data.apiStatus != "success"){
                history.push('/home');
            }
            setCourses(data.data);
            if (data.data.length > perPage) {
                setPageNumber(pageNumber + 1);
                setPerPage(perPage);
            }
            setCoursesLoading(false);
        } catch (error) {
            console.log("Courses cannot be fetched.", error);
        }
    }


    return (
        <>
                {
                    coursesLoading ?
                        <>
                            <PreviewCardLoading />
                            <PreviewCardLoading />
                            <PreviewCardLoading />
                            <PreviewCardLoading />
                        </>
                        :
                        courses?.map((data, i) => {
                            return (
                                <PreviewCard
                                    preview={data}
                                    key={i}
                                />
                            );
                        })

                }

        </>
    );
}

export default Pagination;