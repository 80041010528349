import React, { useState } from "react";
import styles from "./CoursePreviewOptions.module.css";

const CoursePreviewOptions = ({ selectedOption, setSelectedOption }) => {
  return (
    <div className={styles.cpo_container}>
      <div className={styles.cpo_content}>
        <div
          className={`${styles.cpo_options} ${
            selectedOption === `preview` ? `${styles.selected_option}` : " "
          }`}
          onClick={() => setSelectedOption("preview")}
        >
          Course Preview
        </div>
        <div
          className={`${styles.cpo_options} ${
            selectedOption === `mentor` ? `${styles.selected_option}` : " "
          }`}
          onClick={() => setSelectedOption("mentor")}
        >
          About the Mentor
        </div>
      </div>
    </div>
  );
};

export default CoursePreviewOptions;
