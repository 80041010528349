import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";

const CourseCertificateEmpty = (props) => {
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        getCourseData();
    }, []);

    const getCourseData = async () => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${props?.courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const data = (await response.json());
            setCourseData(data.data);
        } catch (error) {
            console.log("Featured Courses cannot be fetched.", error);
        }
    }

    return (
        <>
            <Grid item xs={12} sm={6} md={4} lg={3} className="flex justify-center">
                <Link to={`/learn/${props?.courseSlug}`}>
                    <Card className="card">
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia
                                component="img"
                                style={{ width: "280px", height: "360px", borderRadius: "10px" }}
                                image="https://firebasestorage.googleapis.com/v0/b/elearning-monks.appspot.com/o/certificates%2Fcertificate-soon.jpeg?alt=media&token=d95cee15-c960-4911-bb55-4f86382c8bbb"
                                alt="Complete Course to Get Certificate"
                                className="relative"
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: "30%",
                                    left: "10%",
                                    width: '80%',
                                    color: '#ad2831',
                                    padding: '10px',
                                }}
                            >
                                <Typography style={{ fontSize: "1.25rem", fontWeight: "700" }}>
                                    Complete Course to Get Certificate
                                </Typography>
                            </Box>
                        </Box>
                        <CardContent style={{ paddingBottom: "0px", marginBottom:"0px" }}>
                            <Typography style={{ color: "#ad2831", fontWeight: "bold" }}>
                                {courseData?.title}
                            </Typography>
                        </CardContent>
                    </Card>
                </Link>
            </Grid>
        </>
    );
}

export default CourseCertificateEmpty;