import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { storage } from "../../firebase";

const GoBack = (props) => {

    useEffect(async () => {
        if (props?.src !== "" && props?.src !== null && props?.src !== undefined && props?.cred !== "" && props?.certDate !== "" && props?.idToken !== "") {
            await imageUpload(props?.src.split(',')[1], props?.cred, props?.idToken, props?.certDate);
        }
    }, [props]);

    const history = useHistory();

    const imageUpload = async (src, cred, idToken, certDate) => {

        const uploadTask = storage.ref('certificates').child(cred + ".png").putString(src, 'base64', { contentType: 'image/png' });
        uploadTask.on(
            'state_changed',
            snapshot => {
            }, error => {
                console.log(error);
            }, () => {
                storage.ref("certificates").child(cred + ".png").getDownloadURL().then( async url => {
                    try {
                        const response = await fetch(`https://elearning-monks.azurewebsites.net/certificate/create/${props?.courseSlug}`, {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                token: idToken,
                                credentialID: cred,
                                certificateUrl: url,
                                dateOfCertification: certDate
                            })
                        });
                        const creation = (await response.json());
                        if (creation.apiStatus && creation.statusCode && creation.statusCode == 409) {
                            history.push(`/certificate/${props?.courseSlug}?credID=${cred}`);
                        }
                        if (creation.apiStatus && creation.apiStatus != "SUCCESS" && creation.apiStatus != "Success" && creation.apiStatus != "success") {
                            history.push(`/course/${props?.courseSlug}`);
                        }
                        history.push(`/certificate/${props?.courseSlug}?credID=${cred}`);
                    } catch (error) {
                        console.log("Error While Posting Certificate", error);
                    }
                })
            });
    }


    return (
        <>
        </>
    );
}

export default GoBack;