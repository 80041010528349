import React, { useState } from "react";
import TaskResponses from "./TaskResponses";
import { Grid, Typography } from "@material-ui/core";

const UserTasks = (props) => {

  
    return (
        <>
            <Grid container>
                <Typography
                    align="center"
                    style={{
                        fontSize: "32px",
                        color: "#6495ED",
                        fontWeight: "bold",
                        marginBottom: "5px",
                        padding: "10px"
                    }}
                >
                    User Tasks
                </Typography>
            </Grid>

            <Grid container>
                {
                    props?.tasks?.map((task, i) => {
                        return (
                            <TaskResponses task={task} key={i} />
                        )
                    })
                }
            </Grid>

        </>
    );
}

export default UserTasks;