import React, { useState } from "react";
import HeaderCourse from "../../component/mini_diploma_component/HeaderCourse";
import LearningInPrograme from "../../component/mini_diploma_component/LearningInPrograme";
import StudentTestimonials from "../../component/mini_diploma_component/StudentTestimonials";
import WorkshopFor from "../../component/mini_diploma_component/WorkshopFor";
import CoursePreviewOptions from "../../component/mini_diploma_component/CoursePreviewOptions";
import BuyMessage from "../../component/mini_diploma_component/BuyMessage";
import Info from "../../component/mini_diploma_component/Info";
import MoreInfo from "../../component/mini_diploma_component/MoreInfo";
import SneakPeek from "../../component/mini_diploma_component/SneakPeek";
import FAQ from "../../component/mini_diploma_component/FAQ";
import TransformCareer from "../../component/mini_diploma_component/TransformCareer";
import TrainerInfo from "../../component/mini_diploma_component/trainer/TrainerInfo";
import Events from "../../component/mini_diploma_component/trainer/Events";
import JoinDiplomaContainer from "../../component/mini_diploma_component/trainer/JoinDiplomaContainer";
// import BrandsMiniDiploma from "../../component/mini_diploma_component/"
import TrainerPosts from "../../component/mini_diploma_component/trainer/TrainerPosts";
import BrandsMiniDiploma from "../../component/mini_diploma_component/Brands";

const MiniDiploma = () => {
  const [selectedOption, setSelectedOption] = useState("preview");
  const [selectedtestimonailOption, setSelectedtestimonailOption] =
    useState("recruiter");

  return (
    <>
      <HeaderCourse />
      <div style={{ position: "relative", zIndex: "2" }}>
        <CoursePreviewOptions
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        {selectedOption === "preview" ? (
          <SneakPeek />
        ) : (
          <>
            <TrainerInfo />
          </>
        )}
      </div>
      {selectedOption === "preview" ? (
        <>
          <BuyMessage />
          <Info />
          <LearningInPrograme />
          <MoreInfo />
          <WorkshopFor />
          <StudentTestimonials
            selectedtestimonailOption={selectedtestimonailOption}
            setSelectedtestimonailOption={setSelectedtestimonailOption}
          />
          <BrandsMiniDiploma />
          <FAQ />
          <TransformCareer />
        </>
      ) : (
        <>
          <TrainerPosts />
          <Events />
          {/* <TrainerTestimonial /> */}
          <JoinDiplomaContainer />
        </>
      )}
    </>
  );
};

export default MiniDiploma;
