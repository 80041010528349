import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button, CircularProgress, Grid } from '@material-ui/core';

const DoubtText = (props) => {
    const [loading, setLoading] = useState(true);
    const [doubtData, setDoubtData] = useState({});
    const history = useHistory();

    useEffect(() => {
        getDoubt();
    }, []);

    const getDoubt = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/doubts/get/doubt`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    },
                    body: JSON.stringify({
                        doubtID: props?.doubtID
                    })
                });
                const selectedDoubtData = (await response.json());
                console.log(selectedDoubtData);
                if (selectedDoubtData.apiStatus != "Success" && selectedDoubtData.apiStatus != "SUCCESS" && selectedDoubtData.apiStatus != "success") {
                    history.push("/admin/users");
                }
                setDoubtData(selectedDoubtData.data);
                setLoading(false);
            } catch (error) {
                console.log("Courses cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }



    return (
        <>
            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    <Grid container className="flex items-center p-1 border-2 bg-gray-100 hover:bg-gray-200 font-medium text-center">
                        <Grid item xs={12} sm={6} md={2} className="py-1">
                            {doubtData?.courseName}
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} className="py-1">
                            {doubtData?.moduleTitle}
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} className="py-1">
                            {doubtData?.doubtTxt?.substr(0, 100)}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className="py-1">
                            <Button variant="contained" color="primary" className="button-default">
                                <Link to={`/admin/doubt/${doubtData?._id}`} target="_blank">Answers</Link>
                            </Button>
                        </Grid>
                    </Grid>
            }
        </>
    );
}

export default DoubtText;