// Global Imports 
import React from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Other Components 
import Header from "../../component/global/Header";
import Payment from "../../component/courses/Payment"
import Footer from "../../component/global/Footer";
import {
    selectUserName,
    selectUserEmail
} from "../../Providers/userSlice";
import { auth, firebaseAnalytics } from "../../firebase";


const Cart = () => {
    const history = useHistory();
    const userName = useSelector(selectUserName);
    const userEmail = useSelector(selectUserEmail);
    const { courseSlug } = useParams();

    useEffect(() => {
        document.title = "Cart | Elearning - Recruiting Monk";
        if (courseSlug === undefined || courseSlug === "" || courseSlug === null) {
            history.push("/courses");
        } else {
            authCheck();
        }
    }, [courseSlug]);

    const authCheck = () => {
        auth.onAuthStateChanged(async function (user) {
            if (user) {
                // User is signed in.
                var userData = await auth.currentUser;

                if (userData != null) {
                    await auth.currentUser.getIdToken().then(async function (idToken) {
                        firebaseAnalytics.logEvent(`${courseSlug}_cart_visited`);
                    }).catch(function (error) {
                        console.log(error)
                        history.push(`/course/${courseSlug}`);
                    });
                } else {
                    console.log("else of userData");
                    history.push(`/course/${courseSlug}`);
                }

            } else {
                console.log("else of user");
                history.push(`/course/${courseSlug}`);
            }
        });
    }






    return (
        <>

            <Header />
            <Payment name={userName} email={userEmail} />
            <Footer />
        </>
    );
}

export default Cart;