import { Grid } from "@material-ui/core";
import React from "react";

const CourseViewsLoading = () => {
    return (
        <>
            <div className="p-2">
                <div className="bg-red-300 animate-pulse h-10 w-1/2 md:w-1/4 mb-3"></div>
            </div>
            <Grid container>
                <Grid item xs={12} md={3}>
                    <p className="animate-pulse h-10 w-full p-2 bg-gray-200"></p>
                </Grid>
                <Grid item className="hidden md:flex" md={3}>
                    <p className="animate-pulse h-10 w-full p-2 bg-gray-200"></p>
                </Grid>
                <Grid item className="hidden md:flex" md={3}>
                    <p className="animate-pulse h-10 w-full p-2 bg-gray-200"></p>
                </Grid>
                <Grid item className="hidden md:flex" md={3}>
                    <p className="animate-pulse h-10 w-full p-2 bg-gray-200"></p>
                </Grid>
            </Grid>
        </>
    );
}

export default CourseViewsLoading;