const LandingCourse = () => {

  return (
    <section className="section_cirriculum wf-section">
      <div className="padding-global padding-section-large">
        <div className="container-large">
          <div className="curriculum_wrapper">
            <div className="course_accordian-wrapper">
              <h2>Course curriculum</h2>
              <div className="accordian_wrapper">
                <div className="accordian_banner">
                  <div className="accordian_content">
                    <div className="html-embed-7 w-embed">
                      <svg
                        width="1"
                        height="19"
                        viewBox="0 0 1 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="0.5"
                          y1="0.5"
                          x2="0.499999"
                          y2="18.5"
                          stroke="white"
                          strokeOpacity="0.2"
                        />
                      </svg>
                    </div>
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                      10+ Modules&nbsp;&nbsp;&nbsp; ||&nbsp;&nbsp;&nbsp; 3 - 4
                      Hours of learning
                    </p>
                  </div>
                </div>
                <div className="course_list">
                  <div
                    data-w-id="97daae01-571e-2056-1a22-b473f40ae9ce"
                    className="accordion-item-wrapper space-between"
                    style={{ borderBottom: "0px" }}
                  >
                    <div className="accordion-content-wrapper width-660px">
                      <div className="accordion-header"></div>
                      <div className="acordion-body">
                        <div className="text-block-2">Course Outline : </div>
                        <div className="accordion-spacer-2"></div>
                        <div className="mg-bottom-2">
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>Introduction to StoryTelling</p>
                            </div>
                          </div>
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>Stories create everlasting impact</p>
                            </div>
                          </div>
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>Our current challenges</p>
                            </div>
                          </div>
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>The Storytelling framework</p>
                            </div>
                          </div>
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>How to craft stories from scratch</p>
                            </div>
                          </div>
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>Story = data = business</p>
                            </div>
                          </div>
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>Mind Map for storytelling</p>
                            </div>
                          </div>
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>Assignment</p>
                            </div>
                          </div>
                          <div className="coursecurriculum_body-wrap">
                            <div className="div-block-18">
                              <img
                                src="fonts/643d14ce0366e365cb849135_camera-video-fill.svg"
                                loading="lazy"
                                alt="true"
                              />
                              <p>Final discussion</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_277cf872-7383-349e-3341-c22f57d52c5b-e2f73468"
              className="curriculum_card-wrapper"
            >
              <img
                src="images/ashfaq_banner.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 98vw, (max-width: 991px) 83vw, 27vw"
                alt="true"
              />
              <div className="curriculum_heading-wrapper">
                <h3>Impact Lives for Meaningful Stories</h3>
                <div style={{ display: "flex", justify: "center", alignItems: "center", gap:"10px"}}>
                  <p>Instructed by Ashfaq Ahmed</p>
                  <a style={{marginTop:"5px"}}
                   href="https://www.linkedin.com/in/ashfaqahmedhr/"
                   target="_blank">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                    >
                      <path
                        fill="#0A66C2"
                        d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 0 1-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 0 0 2 2.866v10.268a.88.88 0 0 0 .885.866h10.226a.882.882 0 0 0 .889-.866V2.865a.88.88 0 0 0-.889-.864z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="div-block-2">
                <button
                  className="button w-button"
                >
                  Register today for ₹1,599.00
                </button>
                <p className="text-size-small text-align-center">
                  Hurry Up! Reserve your seat before August 24, 2023
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingCourse;
