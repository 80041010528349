import React, { useState } from 'react'
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';

const TableSearch = (props) => {
    const columns = props.tableColumns;
    const data = props.tableData;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        usePagination,
    )

    const { pageIndex, pageSize, globalFilter } = state

    const [value, setValue] = useState(globalFilter)

    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
        gotoPage(0)
    }, 1000)


    return (
        <>
            <div className="w-full flex justify-center items-center">
                <input
                    placeholder="Search for User Name, Email"
                    value={value || ''}
                    type="text"
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    size="50"
                    className="border-2 p-2"
                />
            </div>
            <table {...getTableProps()} className='admin-table'>
                <thead className='thead-dark'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="w-full flex justify-center">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="m-2">
                    {'<<'}
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="m-2">
                    Previous
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage} className="m-2">
                    Next
                </button>
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="m-2">
                    {'>>'}
                </button>
            </div>
            <div className="w-full flex justify-center my-2">
                <span>
                    Page
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <span className='mx-3'>
                    | Go to page:
                    <input
                        type='number'
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(pageNumber)
                        }}
                        style={{ width: '50px' }}
                    />
                </span>
            </div>
            <div className="w-full flex justify-center my-2">
                <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}>
                    {[10, 25, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

export default TableSearch;
