// Global Imports
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// other components import
import Description from "./sections/Description";
import Duration from "./sections/Duration";
import Ideal from "./sections/Ideal";
import Info from "./sections/Info";
import Media from "./sections/Media";
import Perks from "./sections/Perks";
import Pricing from "./sections/Pricing";
import Purview from "./sections/Purview";
import Stats from "./sections/Stats";
import Title from "./sections/Title";
import Author from "../courses/Author";
import Skills from "./sections/Skills";
import Testimonials from "./sections/Testimonials";
import Loading from "./sections/Loading";
import { CircularProgress } from "@material-ui/core";
import VideoMessageSection from "./sections/VideoMessageSection";
import SuccessSection from "./sections/SuccessSection";
import { firebaseAnalytics } from "../../firebase";

const CourseDetails = () => {
    const [courseData, setCourseData] = useState([]);
    const [courseDataLoading, setCourseDataLoading] = useState(true);
    // const [courseStudentVideoData, setCourseStudentVideoData] = useState([]);
    // const [courseStudentVideoDataLoading, setCourseStudentVideoDataLoading] = useState(true);
    // const [courseSuccessfulStudentData, setCourseSuccessfulStudentData] = useState([]);
    // const [courseSuccessfulStudentDataLoading, setCourseSuccessfulStudentDataLoading] = useState(true);
    const history = useHistory();

    const { courseSlug } = useParams();

    useEffect(() => {
        getCourseData();
        // getCourseStudentVideoData();
        // getCourseSuccessfulStudentData();
    }, []);

    const getCourseData = async () => {
        try {
            setCourseDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const selectedCourseData = (await response.json());
            if (selectedCourseData.apiStatus && selectedCourseData.apiStatus != "SUCCESS" && selectedCourseData.apiStatus != "Success" && selectedCourseData.apiStatus != "success") {
                history.push('/courses');
            }
            setCourseData(selectedCourseData.data);
            setCourseDataLoading(false);
            firebaseAnalytics.logEvent(`${courseSlug}_overview_visited`);
        } catch (error) {
            console.log("Courses cannot be fetched.", error);
        }
    }


    // const getCourseStudentVideoData = async () => {
    //     try {
    //         setCourseStudentVideoDataLoading(true);
    //         const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/students/${courseSlug}?perPage=6&pageNumber=1`, {
    //             method: "GET",
    //             headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json"
    //             }
    //         });
    //         const selectedCourseData = (await response.json());
    //         if (selectedCourseData.apiStatus && selectedCourseData.apiStatus != "SUCCESS" && selectedCourseData.apiStatus != "Success" && selectedCourseData.apiStatus != "success") {
    //             history.push('/courses');
    //         }
    //         setCourseStudentVideoData(selectedCourseData.data);
    //         setCourseStudentVideoDataLoading(false);
    //     } catch (error) {
    //         console.log("Courses Student Videos cannot be fetched.", error);
    //     }
    // }


    // const getCourseSuccessfulStudentData = async () => {
    //     try {
    //         setCourseSuccessfulStudentDataLoading(true);
    //         const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/success/${courseSlug}`, {
    //             method: "GET",
    //             headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json"
    //             }
    //         });
    //         const selectedCourseData = (await response.json());
    //         if (selectedCourseData.apiStatus && selectedCourseData.apiStatus != "SUCCESS" && selectedCourseData.apiStatus != "Success" && selectedCourseData.apiStatus != "success") {
    //             history.push('/courses');
    //         }
    //         setCourseSuccessfulStudentData(selectedCourseData.data);
    //         setCourseSuccessfulStudentDataLoading(false);
    //     } catch (error) {
    //         console.log("Courses Student Videos cannot be fetched.", error);
    //     }
    // }



    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-12 px-4 md:px-20 md:gap-10 mt-6 mb-20">
                {
                    courseDataLoading
                        ?
                        <Loading />
                        :
                        <>
                            <div style={{ width: "85vw" }} >
                                <Title title={courseData.title} />
                                <div className="grid grid-cols-1 md:grid-cols-4 w-full gap-4 md:gap-0 mb-20">
                                    <div className="md:col-span-2">
                                        <div className="grid grid-cols-5 w-full gap-4 md:mb-12">
                                            <Purview purview={courseData.overview} />
                                            <Description desc={courseData.description} />
                                            <div className="col-span-5 gap-4 flex-row items-center">
                                                <Ideal ideal={courseData.idealFor} />
                                                <Duration duration={courseData.duration} />
                                                <Info />
                                                <Perks perks={courseData.perks} />
                                            </div>
                                            <Stats takenBy={courseData.takenBy} viewedBy={courseData.viewedBy} />
                                        </div>
                                        <Pricing price={courseData.price} language={courseData.language} courseSlug={courseData.courseSlug} contactURL={courseData.contactURL} btnTxt={courseData.btnTxt} />
                                    </div>
                                    <Media video={courseData.previewVideoUrl} image={courseData.previewImageUrl} />
                                </div>
                                <Author authorID={courseData.authorID} />
                                <Skills skills={courseData.skills} />
                                {/* {
                                    courseSuccessfulStudentDataLoading ?
                                        <div className="w-screen h-screen grid place-items-center">
                                            <CircularProgress />
                                        </div>
                                        :
                                        courseSuccessfulStudentData.length > 0 ?
                                            <SuccessSection successfulStudentData={courseSuccessfulStudentData} courseSlug={courseSlug} />
                                            :
                                            null
                                }
                                {
                                    courseStudentVideoDataLoading ?
                                        <div className="w-screen h-screen grid place-items-center">
                                            <CircularProgress />
                                        </div>
                                        :
                                        courseStudentVideoData.length > 0 ?
                                            <VideoMessageSection studentVideoData={courseStudentVideoData} courseSlug={courseSlug} />
                                            :
                                            null
                                } */}
                                {
                                    courseData.testimonials.length > 0 ?
                                        <Testimonials testimonials={courseData.testimonials} />
                                        : null
                                }
                            </div>
                        </>

                }
            </div>
        </>
    );
}

export default CourseDetails;