import React, { useState, useEffect } from "react";
import TaskResponses from "./TaskResponses";

import TasksEmpty from "./empty/TasksEmpty";
import TasksLoading from "./loading/TasksLoading";
import { Grid, Typography } from "@material-ui/core";

const Tasks = (props) => {
    const [userTasks, setUserTasks] = useState(null);
    useEffect(() => {
        setUserTasks(props?.data);
    }, [props?.data]);


    return (
        <>
            {
                userTasks ?
                    <>
                        <Grid container>
                            <Typography
                                align="center"
                                style={{
                                    fontSize: "24px",
                                    color: "#ad2831",
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    paddingBottom: "5px",
                                }}
                            >
                                Tasks You Attempted
                            </Typography>
                        </Grid>
                        <Grid container>
                            {
                                userTasks.length > 0 ?
                                    userTasks?.map((task, i) => {
                                        return (
                                            <TaskResponses task={task} key={i} />
                                        );
                                    })
                                    : <TasksEmpty />
                            }
                        </Grid>
                    </>
                    : <TasksLoading />
            }


        </>
    );
}

export default Tasks;