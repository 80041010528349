import React from "react";
import styles from "./JoinDiplomaContainer.module.css"

const JoinDiplomaContainer = () => {
  return (

    <div className={styles.tc_container}>
      <div className={styles.tc_subcontainer}>
        <div className={styles.tc_heading}>
          Ready to transform your recruiting career?
        </div>

        <div className="tc-points" style={{ paddingBottom: "1rem" }}>
          <div className="tc-subpoints">
            <div
              className="tc-point-content"
              style={{ fontSize: "18px", textAlign: "center" }}
            >
              Join the Mini Diploma today and learn directly from industry
              <br />
              experts like Ashfaq!
            </div>
          </div>{" "}
        </div>

        <button
          className={styles.join_diploma}
          onClick={() => window.open("https://wa.me/+917416266999", "_blank")}
        >
          Join Diploma
        </button>
      </div>
    </div>
  );
};

export default JoinDiplomaContainer;
