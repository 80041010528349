// Global Imports 
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

// Other Components imports 
import FeaturedCard from "./FeaturedCard";
import FeaturedCardLoading from "./FeaturedCardLoading";

// Design Imports
import {
    Typography,
    Grid
} from "@material-ui/core";

const scrollToRef = (ref) => window.scrollTo(0, 0);

const Featured = () => {

    const [featured, setFeatured] = useState([]);
    const [featuredLoading, setFeaturedLoading] = useState(false);
    const topRef = useRef(null);
    const executeScroll = () => scrollToRef(topRef);

    useEffect(() => {
        getFeaturedCourses();
    }, []);

    const getFeaturedCourses = async () => {
        try {
            setFeaturedLoading(true);
            const response = await fetch("https://elearning-monks.azurewebsites.net/courses/list?type=featured", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const data = (await response.json());
            setFeatured(data.data);
            setFeaturedLoading(false);
        } catch (error) {
            console.log("Featured Courses cannot be fetched.", error);
        }
    }

    return (
        <>
            <section className="text-gray-700 body-font w-full">
                <div className="container px-5 pt-12 pb-6 mx-auto">
                    <Grid item xs={12} >
                        <Typography
                            align="center"
                            style={{
                                fontSize: "32px",
                                color: "#ad2831",
                                fontWeight: "bold",
                                marginBottom: "5px",
                            }}
                        >
                            Featured Courses
                        </Typography>
                    </Grid>
                    <div className="flex flex-wrap p-2 justify-center w-full">
                        {
                            featuredLoading ?
                                <>
                                    <FeaturedCardLoading />
                                    <FeaturedCardLoading />
                                    <FeaturedCardLoading />
                                    <FeaturedCardLoading />
                                </>
                                :
                                featured?.map((data, i) => {
                                    return (
                                        <FeaturedCard
                                            key={i}
                                            featured={data}
                                        />
                                    );
                                })
                        }

                    </div>
                </div>
                <Grid
                    item
                    xs={12}
                    style={{
                        display: "flex",
                        fontWeight: "bold",
                        justifyContent: "center",
                    }}
                >
                    <Link
                        className="Homepage_show_all_courses"
                        to="/courses"
                        onClick={executeScroll}
                    >
                        <Typography
                            className="Homepage_show_all_courses"
                            style={{
                                color: "#ad2831",
                                fontWeight: "bold",
                                fontSize: "19px",
                                paddingTop: "4px",
                                textDecoration: "underline",
                            }}
                        >
                            Show All Courses
                        </Typography>
                    </Link>
                </Grid>


            </section>
        </>
    );
}

export default Featured;