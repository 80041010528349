// 
import React, { useEffect } from "react";

// Other Components
import Footer from "../../component/global/Footer";
import Header from "../../component/global/Header";
import CoursesList from "../../component/preview/CoursesList";
import { firebaseAnalytics } from "../../firebase";

const AllCourses = () => {
    useEffect(() => {
        document.title="Courses | Elearning - Recruiting Monk";
        firebaseAnalytics.logEvent("courses_visited");
    });
    return (
        <>
            <Header />
            <CoursesList />
            <Footer />
        </>
    );
}

export default AllCourses;