// Global Imports
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { LinkedinShareButton, LinkedinIcon } from "react-share";
import { Helmet } from "react-helmet";

import domtoimage from 'dom-to-image';

// Other Files import
import GoBack from "../../component/certificate/GoBack";
import Footer from "../../component/global/Footer";
import Header from "../../component/global/Header";
import { auth } from "../../firebase";
import {
    selectUserName,
} from "../../Providers/userSlice";


// Design imports
import { Button, Grid, Typography } from "@material-ui/core";

/*  

Certificate Controllers
       if(credID){
            if(validate(credID)){
                if(certificateDataFound){
                    displayCertificate;
                    if(checkAuth){
                        setLoggedIn;
                        if(checkOwner){
                            setOwner;
                            goBackBtn;
                            optionToShare;
                            optionToDownload;
                        }else{
                            setNotOwner;
                            giveLinkToPreview;
                        }
                    }else{
                        setNotLoggedIn;
                        giveLinkToPreview;
                    }
                }else{
                    redirectToCoursePreview;
                }
            }else{
                redirectToCoursePreview;
            }            
        }else{    
            if(checkAuth){
                setLoggedIn;
                if(checkCourseBought){
                    setPurchased;
                    if(checkCourseCompleted){
                        setCompleted;
                        if(certificateAttached){
                            redirectToCertifcateLink;
                        }else{
                            generateCertificate;
                            redirectToCertificateLink;
                        }
                    }else{
                        setNotCompleted;
                        giveLinkToLearn;
                    }
                }else{
                    setNotPurchased;
                    giveLinkToPreview
                }
            }else{
                setNotLoggedIn;
                giveLinkToPreview
            }
        }

*/

const Certificate = () => {
    const [owner, setOwner] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [purchased, setPurchased] = useState(false);
    const [isCredValid, setIsCredValid] = useState(false);
    const [courseCompleted, setCourseCompleted] = useState(false);
    const [courseName, setCourseName] = useState("");
    const [courseImageUrl, setCourseImageUrl] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [token, setToken] = useState("");
    const [certificateData, setCertificateData] = useState({});
    const [userCourseTaken, setUserCourseTaken] = useState({});
    const [dateString, setDateString] = useState("");
    const [credentialID, setCredentialID] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const userName = useSelector(selectUserName);
    const { courseSlug } = useParams();
    const history = useHistory();
    const search = useLocation().search;
    var credID = new URLSearchParams(search).get('credID');


    useEffect(() => {
        document.title = "Certificate | Elearning - Recruiting Monk";
        certificateController();
    }, [credID]);


    const certificateController = async () => {

        const isCourseSlugValid = await validateCourseSlug(courseSlug);
        if (isCourseSlugValid) {
            if (credID !== undefined && credID !== "" && credID !== null && credID !== "undefined") {
                const isCredIDValid = await validateCredId(credID);
                if (isCredIDValid) {
                    console.log("Display Certificate Data");
                    auth.onAuthStateChanged(async function (user) {
                        if (user) {
                            var userData = auth.currentUser;
                            if (userData != null) {
                                await auth.currentUser.getIdToken().then(async function (idToken) {
                                    setToken(idToken);
                                    setLoggedIn(true);
                                    const isEnrolled = await checkPurchase(idToken);
                                    if (isEnrolled) {
                                        setPurchased(true);
                                        const isOwner = await checkOwner(idToken, credID);
                                        if (isOwner) {
                                            setOwner(true);
                                        } else {
                                            setOwner(false);
                                        }
                                    } else {
                                        setPurchased(false);
                                    }
                                }).catch(function (error) {
                                    setLoggedIn(false);
                                });
                            } else {
                                setLoggedIn(false);
                            }
                        } else {
                            setLoggedIn(false);
                        }
                    });
                } else {
                    history.push(`/course/${courseSlug}`);
                }
            } else {
                auth.onAuthStateChanged(async function (user) {
                    if (user) {
                        var userData = auth.currentUser;
                        if (userData != null) {
                            await auth.currentUser.getIdToken().then(async function (idToken) {
                                setToken(idToken);
                                setLoggedIn(true);
                                const isEnrolled = await checkPurchase(idToken);
                                if (isEnrolled) {
                                    setPurchased(true);
                                    const isCompleted = await checkCompleted(idToken);
                                    if (isCompleted) {
                                        setCourseCompleted(true);
                                        generateCertificate(idToken);
                                    } else {
                                        setCourseCompleted(false);
                                    }
                                } else {
                                    setPurchased(false);
                                }
                            }).catch(function (error) {
                                setLoggedIn(false);

                            });
                        } else {
                            setLoggedIn(false);
                        }
                    } else {
                        setLoggedIn(false);
                    }
                });
            }
        } else {
            history.push("/courses");
        }

    }




    const validateCourseSlug = async (courseSlug) => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/certificate/validate/course/${courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const validate = (await response.json());
            if (validate.apiStatus && validate.apiStatus != "SUCCESS" && validate.apiStatus != "Success" && validate.apiStatus != "success") {
                return false;
            }
            setCourseName(validate?.data?.title);
            setCourseImageUrl(validate?.data?.shareImageUrl);
            setAuthorName(validate?.data?.author);
            return true;
        } catch (error) {
            console.log("Error in Validating Course Slug", error);
            return false;
        }
    }


    const isNumeric = (num) => {
        return !isNaN(num)
    }




    const validateCredId = async (credID) => {
        const credArray = credID.split('-');
        // credArray[0] = "RMK" -> constant,
        // credArray[1] = "smc" -> course name initials
        // credArray[2] = "9090" -> certificate count of the course + 1 
        // credArray[3] = "abcd" -> random string
        // credArray[4] = "aaa" -> string maintaining count above 9999 

        const courseSlugArray = courseSlug.split('-');
        var courseInitials = "";
        for (let i = 0; i < courseSlugArray.length; i++) {
            courseInitials += courseSlugArray[i][0];
        }

        if (credArray[0] === "RMK" && credArray[1] === courseInitials && isNumeric(credArray[2]) && !isNumeric(credArray[3]) && credArray[3].length === 4 && !isNumeric(credArray[4]) && credArray[4].length === 3) {
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/certificate/validate/credential/${credID}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
                const validate = (await response.json());
                if (validate.apiStatus && validate.apiStatus != "SUCCESS" && validate.apiStatus != "Success" && validate.apiStatus != "success") {
                    return false;
                }
                setCertificateData(validate?.data);
                setIsCredValid(true);
                return true;
            } catch (error) {
                console.log("Error in Validating Credential ID", error);
                return false;
            }
        } else {
            return false;
        }
    }







    const checkPurchase = async (idToken) => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/taken/check/${courseSlug}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const checking = (await response.json());
            if (checking.apiStatus && checking.apiStatus != "SUCCESS" && checking.apiStatus != "Success" && checking.apiStatus != "success") {
                return false;
            }
            if (checking.data === "PURCHASED") {
                return true;
            } else if (checking.data === "NOT_PURCHASED") {
                return false;
            } else {
                return false;
            }
        } catch (error) {
            console.log("Error in Purchase Check", error);
            return false;
        }
    }





    const checkCompleted = async (idToken) => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/certificate/validate/completion/${courseSlug}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const checking = (await response.json());
            if (checking.apiStatus && checking.apiStatus != "SUCCESS" && checking.apiStatus != "Success" && checking.apiStatus != "success") {
                return false;
            }
            if (checking.data) {
                setUserCourseTaken(checking?.data);
                if (checking?.data?.certificate != "") {
                    history.push(`/certificate/${courseSlug}?credID=${checking?.data?.certificate}`);
                    // return true;
                } else {
                    return true;
                }
            }
        } catch (error) {
            console.log("Error in Course Completion Check", error);
            return false;
        }

    }



    const checkOwner = async (idToken, credID) => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/certificate/validate/owner/${courseSlug}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken,
                    credID
                })
            });
            const checking = (await response.json());
            if (checking.apiStatus && checking.apiStatus != "SUCCESS" && checking.apiStatus != "Success" && checking.apiStatus != "success") {
                return false;
            }
            return true;
        } catch (error) {
            console.log("Error in Certificate Owner Check", error);
            return false;
        }
    }


    const generateCertificate = async (idToken) => {
        certificationDate();
        await generateCredentialId();
        handleDomToImage();
    }


    const generateCredentialId = async () => {
        var str = "";
        const courseSlugArray = courseSlug.split('-');
        var courseInitials = "";
        for (let i = 0; i < courseSlugArray.length; i++) {
            courseInitials += courseSlugArray[i][0];
        }
        var numericCount = "";
        var alphabeticalCount = "";
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/certificate/create/counts/${courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const credentialCounts = (await response.json());
            if (credentialCounts.apiStatus && credentialCounts.apiStatus != "SUCCESS" && credentialCounts.apiStatus != "Success" && credentialCounts.apiStatus != "success") {
                numericCount = "1234";
                alphabeticalCount = randomString(3);
            }
            numericCount = credentialCounts?.data?.numeric;
            alphabeticalCount = credentialCounts?.data?.chars;
        } catch (error) {
            console.log("Error in Fetching Course Certificates", error);
        }

        const arr = ["RMK", courseInitials, numericCount, randomString(4), alphabeticalCount];
        str = arr.join("-");
        setCredentialID(str);
        return str;
    }




    const randomString = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const certificationDate = (apiDate = "") => {
        if (apiDate === "") {
            const formattedDate = new Date()
                .toLocaleDateString({},
                    { timeZone: "Asia/Kolkata", month: "long", day: "2-digit", year: "numeric" }
                )
            setDateString(formattedDate);
            return formattedDate;
        } else {
            setDateString(apiDate);
            return apiDate;
        }
    }

    // function filter (node) {
    //     return (node.tagName !== 'i');
    // }

    const handleDomToImage = () => {

        const node = document.getElementById("certificate");
        var str = "";
        domtoimage.toPng(node)
            .then(function (dataUrl) {
                setImgUrl(dataUrl);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }
    return (
        <>

            {
                courseName != "" && credID != "" && certificateData ?
                    <Helmet>
                        <meta charset="utf-8" />
                        <title>Elearning - Recruiting Monk</title>
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <meta name="theme-color" content="#000000" />
                        <meta name="description" content="Elearning Platform for Recruiters by Recruiting Monk" />
                        <meta name="title" content="Elearning By Recruiting Monk" />
                        <meta name="medium" content="mult" />
                        <meta property="og:title" content="RecruitingMonk Course Completion Certificate" />
                        <meta property="og:description" content={`Hello Connections! I am delighted to share that I have successfully completed " ${courseName} Course " by Ashfaq Ahmed on the Elearning Platform of Recruiting Monks`} />
                        <meta property="og:image" content={certificateData?.certificateUrl} />
                        <meta property="og:type" content="video_lecture" />
                        <meta property="og:site_name" content="Elearning By Recruiting Monk" />
                        <meta property="og:locale" content="en_US" />
                        <meta itemprop="name" content="RecruitingMonk Course Completion Certificate" />
                        <meta itemprop="description" content={`Hello Connections! I am delighted to share that I have successfully completed " ${courseName} Course " by Ashfaq Ahmed on the Elearning Platform of Recruiting Monks`} />
                        <meta itemprop="image" content={certificateData?.certificateUrl} />
                    </Helmet>
                    :
                    <>
                        {
                            courseName !== "" && loggedIn && purchased && courseCompleted && userCourseTaken?.certificate == '' ?
                                <Helmet>
                                    <meta name="description" content={`Hello Connections! I am delighted to share that I have successfully completed " ${courseName} Course " by Ashfaq Ahmed on the Elearning Platform of Recruiting Monks`} />
                                    <meta name="title" content="Elearning By Recruiting Monk" />
                                    <meta name="medium" content="mult" />
                                    <meta property="og:title" content="RecruitingMonk Course Completion Certificate" />
                                    <meta property="og:description" content={`Hello Connections! I am delighted to share that I have successfully completed " ${courseName} Course " by Ashfaq Ahmed on the Elearning Platform of Recruiting Monks`} />
                                    <meta property="og:image" content={imgUrl} />
                                    <meta property="og:type" content="video_lecture" />
                                    <meta property="og:site_name" content="Elearning By Recruiting Monk" />
                                    <meta property="og:locale" content="en_US" />
                                    <meta itemprop="name" content="RecruitingMonk Course Completion Certificate" />
                                    <meta itemprop="description" content={`Hello Connections! I am delighted to share that I have successfully completed " ${courseName} Course " by Ashfaq Ahmed on the Elearning Platform of Recruiting Monks`} />
                                    <meta itemprop="image" content={imgUrl} />
                                </Helmet>
                                :
                                null
                        }
                    </>
            }
            <Header />

            <Grid container className="p-5">
                <Grid item sm={12} md={8}>
                    {
                        courseName !== "" && loggedIn && purchased && courseCompleted && userCourseTaken?.certificate == '' ?
                            <div className="p-3">
                                <div className="text-center relative" id="certificate" style={{ height: "297mm", width: "210mm", border: "2px solid #000", backgroundImage: "url('/images/certificate-bg.png')" }}>
                                    <div className="heading">
                                        CERTIFICATE OF COMPLETION
                                    </div>
                                    <div className="text text-center w-full">
                                        This certificate is awarded to
                                        <div className="certificate-titles" style={{ color: "#b32800", fontWeight: "700", fontFamily: "'Montserrat', sans-serif", textTransform: "capitalize" }}>
                                            {
                                                userName
                                            }
                                        </div>
                                        for successfully completing
                                        <div className="certificate-titles" style={{ color: "#b32800", fontWeight: "700", fontFamily: "'Montserrat', sans-serif" }}>
                                            {
                                                courseName.toUpperCase()
                                            }
                                        </div>
                                        on {dateString}
                                    </div>
                                    <div className="signature text-center w-full">
                                        <center>
                                            <hr style={{ height: "2px", width: "120px", borderWidth: "0", color: "gray", backgroundColor: "gray" }} />
                                        </center>
                                        <div style={{ color: "#b32800", fontWeight: "600", fontFamily: "'PT Serif', serif" }}>
                                            Ashfaq Ahmed
                                        </div>
                                        <div>
                                            Founder and CEO,
                                        </div>
                                        <div>
                                            Recruiting Monk
                                        </div>
                                    </div>
                                    <div className="footing">
                                        <strong>
                                            Credential ID : &nbsp;
                                            {
                                                credentialID
                                            }
                                        </strong>
                                    </div>
                                </div>
                                <GoBack idToken={token} certDate={dateString} cred={credentialID} src={imgUrl} courseSlug={courseSlug} />
                            </div>
                            :
                            <>
                                <img src={certificateData?.certificateUrl} alt="Please Refresh the Page"></img>
                            </>
                    }
                </Grid>
                <Grid item sm={12} md={4}>
                    <div className="p-3 border-2 border-gray-200 overflow-hidden mt-2">
                        <img src={courseImageUrl} alt={courseName} className="w-full h-48" />
                        <Typography
                            style={{
                                fontFamily: "Roboto",
                                color: "#ad2831",
                                fontWeight: "bold",
                                fontSize: "160%"
                            }}
                        >
                            {courseName}
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: "Roboto",
                                color: "#000",
                                fontWeight: "normal",
                                fontSize: "140%"
                            }}
                        >
                            taught by {authorName}
                        </Typography>

                    </div>

                    {
                        owner || (loggedIn && purchased && courseCompleted) ?
                            <>
                                <Grid style={{ marginTop: "10px" }} container>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            className="Freshers_page_Apply_for_free"
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#ad2830",
                                                // marginTop: "10px",
                                            }}
                                        >

                                            <a href={credID != "" && certificateData ? certificateData?.certificateUrl : imgUrl} target="_blank" type="button" download="certificate.png">
                                                Download
                                            </a>
                                        </Button>
                                    </Grid>
                                    <Grid item style={{ paddingLeft: "10px" }}>
                                        <LinkedinShareButton url={window.location.href}
                                        >
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex"
                                            >
                                                <LinkedinIcon
                                                    style={{
                                                        borderRadius: "50%",
                                                        height: "30px",
                                                        width: "30px"
                                                    }}
                                                /> &nbsp; Linkedin
                                            </button>
                                        </LinkedinShareButton>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                {
                                    loggedIn ?
                                        purchased ?
                                            courseCompleted ?
                                                <Button
                                                    variant="contained"
                                                    className="Freshers_page_Apply_for_free"
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: "#ad2830",
                                                        marginTop: "10px",
                                                    }}
                                                >

                                                    <a href={`/certificate/${courseSlug}`}>
                                                        View Your Certificate
                                                    </a>
                                                </Button>
                                                :

                                                <Button
                                                    variant="contained"
                                                    className="Freshers_page_Apply_for_free"
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: "#ad2830",
                                                        marginTop: "10px",
                                                    }}
                                                >

                                                    <a href={`/learn/${courseSlug}`}>
                                                        Continue Course
                                                    </a>
                                                </Button>
                                            :
                                            <Button
                                                variant="contained"
                                                className="Freshers_page_Apply_for_free"
                                                style={{
                                                    color: "#fff",
                                                    backgroundColor: "#ad2830",
                                                    marginTop: "10px",
                                                }}
                                            >

                                                <a href={`/cart/${courseSlug}`}>
                                                    Buy Course
                                                </a>
                                            </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            className="Freshers_page_Apply_for_free"
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#ad2830",
                                                marginTop: "10px",
                                            }}
                                        >

                                            <a href={`/course/${courseSlug}`}>
                                                Preview Course
                                            </a>
                                        </Button>
                                }

                            </>
                    }
                </Grid>
            </Grid>

            <Footer />
        </>
    );
}

export default Certificate;