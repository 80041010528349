// Global Imports 
import React, { useState } from "react"

// Other File Imports
import DoubtReply from "./DoubtReply";

// Design Imports 
import { Avatar } from "@material-ui/core";
import ReplyRoundedIcon from "@material-ui/icons/ReplyRounded";
import { Button, TextField, Divider } from "@material-ui/core";


const Doubt = (props) => {
    const doubt = props.doubt;
    const [replyDiv, setReplyDiv] = useState(false);

    const handleReplyDiv = () => {
        setReplyDiv(!replyDiv);
    }


    return (
        <>
            <div className="ques-div">
                <Avatar
                    style={{ marginRight: "15px" }}
                    alt={doubt.name || "John Doe"}
                    src={
                        doubt.profilePhoto ||
                        "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
                    }
                />
                <div>
                    <p style={{ marginBottom: "10px" }}>
                        <b>{doubt.doubtTxt}</b>
                    </p>
                    <p className="caption">
                        Posted by: {doubt?.name?.substr(0, doubt?.name?.indexOf(" "))}
                    </p>
                </div>
            </div>
            <div align="right">
                <Button onClick={handleReplyDiv}>
                    <ReplyRoundedIcon
                        fontSize="small"
                        style={{ color: "#707070", cursor: "pointer" }}
                    />
                    <span style={{ marginLeft: "5px" }}>Reply</span>
                </Button>
            </div>


            {
                replyDiv ?
                    <DoubtReply answers={doubt.answers} d_id={doubt._id} />
                    :
                    null
            }
        </>
    );
}

export default Doubt;