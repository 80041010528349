// Global Imports
import React from "react";

// Design Imports
import { Add, Visibility } from "@material-ui/icons";

const Stats = (props) => {
    return (
        <>
            <div className="col-span-5 flex flex-row items-center justify-between">
                <div className="gap-4 flex flex-row items-center text-sm justify-between">
                    <div style={{ marginRight: "55px" }} className="font-medium text-blue-700 gap-2 flex flex-row items-center cursor-default">
                        <Add /> Taken:
                        <p className="font-normal">{props.takenBy}</p>
                    </div>
                    <div className="font-medium text-blue-700 gap-2 flex flex-row items-center cursor-default">
                        <Visibility /> Viewed:
                        <p className="font-normal">{props.viewedBy}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Stats;