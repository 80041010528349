// Global Imports 
import React from "react";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Other Components 
import { selectUserName } from "../../../Providers/userSlice";
import Login from "../../global/Login";
import { auth } from "../../../firebase";

// design Imports
import { Button } from "@material-ui/core";

const scrollToRef = (ref) => window.scrollTo(0, 0);

const Pricing = (props) => {
    const userName = useSelector(selectUserName);
    const [enrolled, setEnrolled] = useState(false);
    const history = useHistory();

    const topRef = useRef(null);
    const executeScroll = () => scrollToRef(topRef);


    useEffect(() => {
        purchaseCheck();
    }, [props.courseSlug]);

    const purchaseCheck = () => {
        auth.onAuthStateChanged(async function (user) {
            if (user && props.courseSlug !== undefined) {
                var userData = await auth.currentUser;

                if (userData != null) {

                    await auth.currentUser.getIdToken().then(async function (idToken) {
                        const response = await fetch(`https://elearning-monks.azurewebsites.net/users/taken/check/${props.courseSlug}`, {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                token: idToken
                            })
                        });
                        const checking = (await response.json());
                        if (checking.apiStatus && checking.apiStatus != "SUCCESS" && checking.apiStatus != "Success" && checking.apiStatus != "success") {
                            history.push('/courses');
                        }
                        if (checking.data === "PURCHASED") {
                            setEnrolled(true);
                        } else if (checking.data === "NOT_PURCHASED") {
                            setEnrolled(false);
                        } else {
                            console.log(checking);
                        }

                    }).catch(function (error) {
                        console.log(error)
                    });
                }

            }
        });
    }



    return (
        <>

            <div className="text-md fsont-light flex flex-col md:gap-2 my-2">
                <p>Price: {props.price > 0 ? `₹ ${props.price}/- ` : "EMI Basis"}</p>
            </div>
            <div className="text-md font-light flex flex-col md:gap-2 my-2">
                <p>Language : {props.language} </p>
            </div>
            {
                props.price > 0 ?
                    !userName ?
                        <div>
                            <Login btnName={"Buy Now"} />
                        </div>
                        :
                        enrolled ?
                            <Link to={`/learn/${props.courseSlug}?moduleNo=0`} onClick={executeScroll}>
                                <Button
                                    variant="contained"
                                    className="EM-btn w-full md:w-auto"
                                >
                                    Start
                                </Button>
                            </Link>
                            :
                            <Link to={`/cart/${props.courseSlug}`} onClick={executeScroll}>
                                <Button
                                    variant="contained"
                                    className="EM-btn w-full md:w-auto"
                                >
                                    {props.btnTxt}
                                </Button>
                            </Link>
                    :
                    <Link
                        className="Freshers_page_Apply_for_free"
                        to={{ pathname: props.contactURL }}
                        target="_blank"
                        onClick={executeScroll}
                    >
                        <Button
                            variant="contained"
                            className="Freshers_page_Apply_for_free"
                            style={{
                                color: "#fff",
                                backgroundColor: "#ad2830",
                                marginTop: "10px",
                            }}
                        >
                            {props.btnTxt}
                        </Button>
                    </Link>
            }

        </>
    );
}

export default Pricing;