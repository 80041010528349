import React, { useEffect, useState } from "react";

import { Button, Grid, Drawer, TextField, CircularProgress, Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";

const Checker = (props) => {
    const [courseDataLoading, setCourseDataLoading] = useState([]);
    const [curriculumDataLoading, setCurriculumDataLoading] = useState([]);
    const [moduleDataLoading, setModuleDataLoading] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [courseName, setCourseName] = useState([]);
    const [moduleTitle, setModuleTitle] = useState([]);
    const [responses, setResponses] = useState([
        {
            sno: 1,
            answer: "",
            score: -1
        }
    ]);
    const [totalScores, setTotalScores] = useState(-1);
    const [progressID, setProgressID] = useState(0);
    const [comment, setComment] = useState("");
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const history = useHistory();


    useEffect(() => {
        const data = props.data;
        console.log(data.responses);
        const courseSlug = data.courseSlug;
        const moduleNo = data.moduleNo;
        setResponses(data.responses);
        setComment(data.comment);
        setTotalScores(data.totalScores);
        setEditMode(data.totalScores >= 0 ? false : true)
        setProgressID(data._id);
        getCourseData(courseSlug, 1, 10);
        getCurriculumData(courseSlug, moduleNo);
        getModuleData(courseSlug, moduleNo);
    }, [])

    const getCourseData = async (courseSlug, pageNumber, perPage) => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setCourseDataLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/courses/${courseSlug}?perPage=${perPage + 1}&pageNumber=${pageNumber}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const selectedCourseData = (await response.json());
                setCourseName(selectedCourseData?.data?.title);
                setCourseDataLoading(false);
            } catch (error) {
                console.log("Courses cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    const getCurriculumData = async (courseSlug, moduleNo) => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setCurriculumDataLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/courses/curriculums/${courseSlug}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const selectedCurriculumData = (await response.json());
                setModuleTitle(() => {
                    const modulePreview = selectedCurriculumData?.data?.modules?.find(x => x.moduleNo == moduleNo)
                    return modulePreview?.title;
                })
                setCurriculumDataLoading(false);
            } catch (error) {
                console.log("Curriculum cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }

    const getModuleData = async (courseSlug, moduleNo) => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setModuleDataLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/courses/curriculums/${courseSlug}?moduleNo=${moduleNo}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });

                const selectedModuleData = (await response.json());
                console.log(selectedModuleData);
                setQuestions(questions.concat(selectedModuleData.data.questions).sort((a, b) => (a.sno > b.sno ? 1 : -1)))
                setModuleDataLoading(false);
            } catch (error) {
                console.log("Module cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    const sendScores = async () => {
        var totalMarks = 0;
        for (let i = 0; i < responses.length; i++) {
            if (Number.parseInt(responses[i].score) > -1) {
                totalMarks += Number.parseInt(responses[i].score);
            }
        }

        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;

            try {
                console.log(progressID);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/tests/scores/${progressID}`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    },
                    body: JSON.stringify({
                        totalScores: totalMarks,
                        scores: responses,
                        comment: comment
                    })
                });

                console.log(await response.json());

            } catch (error) {
                console.log("Some error occured while submitting score", error);
            }
        } else {
            history.push("/home");
        }
    }

    const toggleDrawer = (anchor, display) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setOpen(display);
    };

    const handleChange = (event) => {
        setComment(event.target.value);
    };

    const handleScore = i => e => {
        let propertyName = e.target.name;
        let resp = [...responses];
        resp[i][propertyName] = e.target.value;
        setResponses(resp);
    }

    const submitScores = () => {
        sendScores();
    }

    const editHandler = () => {
        setEditMode(true);
    }




    return (
        <>
            <Button
                variant="outlined"
                color="primary"
                onClick={toggleDrawer("top", true)}
            >
                Check
            </Button>

            <Drawer
                anchor="top"
                open={open}
                onClose={toggleDrawer("top", false)}
            >
                <div className="drawer" role="presentation">
                    <Grid container>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "20px",
                                }}
                            >
                                {
                                    courseDataLoading ?
                                        <CircularProgress />
                                        :
                                        <strong style={{ fontSize: "28px" }}>{courseName}</strong>
                                }
                                {
                                    curriculumDataLoading ?
                                        <CircularProgress />
                                        :
                                        <strong style={{ fontSize: "28px" }}>{moduleTitle}</strong>
                                }
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={toggleDrawer("top", false)}
                                >
                                    Close
                                </Button>
                            </div>
                            <div style={{ padding: "20px" }}>
                                {
                                    moduleDataLoading && questions.length === 0 ?
                                        <CircularProgress />
                                        :
                                        <ol>
                                            {questions?.map((attempt, i) => {
                                                const response = responses?.find(x => x.sno == attempt.sno);
                                                return (
                                                    <li key={i}>
                                                        <Divider />
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                Question
                                                                {
                                                                    attempt.sno
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {
                                                                    attempt.question
                                                                }
                                                                {
                                                                    attempt.media !== "" && attempt.media !== undefined && attempt.media !== null ?
                                                                        <img src={attempt.media} alt={attempt.question} /> : null
                                                                }
                                                                {
                                                                    attempt.info !== "" && attempt.info !== undefined && attempt.info !== null ?
                                                                        <ul type="disk" className="pl-2 font-semibold">
                                                                            {
                                                                                attempt?.info?.map((desc, j) => {
                                                                                    return (
                                                                                        <li key={j}>
                                                                                            {
                                                                                                desc
                                                                                            }
                                                                                        </li>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </ul>
                                                                        : null
                                                                }
                                                                {
                                                                    attempt.options !== "" && attempt.options !== undefined && attempt.options !== null && attempt.options.length !== 0 ?
                                                                        <ul type="disk" className="pl-2">
                                                                            {
                                                                                attempt?.options?.map((option, j) => {
                                                                                    return (
                                                                                        <li key={j}>
                                                                                            Option {j + 1} &nbsp; &nbsp; &nbsp; &nbsp;
                                                                                            {
                                                                                                option
                                                                                            }
                                                                                        </li>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </ul>
                                                                        :
                                                                        null
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {
                                                                    response.answer.length > 0 ?
                                                                        <div className="pl-4 font-semibold text-blue-900">
                                                                            <span className="font-bold">Ans. &nbsp; &nbsp;&nbsp;&nbsp;</span>
                                                                            {
                                                                                response.answer
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <span style={{ color: "red" }}>
                                                                            NOT ANSWERED
                                                                        </span>
                                                                }
                                                            </Grid>


                                                            <div style={{ padding: "5px", marginBottom: "10px" }}>
                                                                {
                                                                    response.score >= 0 && !editMode ?
                                                                        <div>
                                                                            Score : {response.score}
                                                                        </div>
                                                                        :
                                                                        <Grid item>
                                                                            <TextField
                                                                                type="number"
                                                                                name="score"
                                                                                placeholder="Score"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={response.score >= 0 ? response.score : 0}
                                                                                onChange={handleScore(i)}
                                                                            />
                                                                        </Grid>

                                                                }
                                                            </div>

                                                        </Grid>
                                                    </li>
                                                )
                                            })
                                            }

                                            <br />
                                        </ol>
                                }


                                {
                                    editMode ?
                                        <TextField
                                            variant="outlined"
                                            placeholder="Add any comment"
                                            name="comment"
                                            value={comment}
                                            onChange={handleChange}
                                            multiline
                                            minRows={2}
                                            maxRows={5}
                                            fullWidth
                                        />
                                        :
                                        <div>
                                            Your Remarks : &nbsp;&nbsp;&nbsp;&nbsp;
                                            {
                                                comment.length > 0 ?
                                                    comment
                                                    :
                                                    <span className="text-red-600 pl-4">
                                                        No Comments Added
                                                    </span>
                                            }
                                        </div>
                                }


                                <div style={{ height: "50px" }}></div>
                                {editMode ? (
                                    <Button
                                        onClick={submitScores}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Submit Score & Comment
                                    </Button>
                                ) : (
                                    <div>
                                        <p>
                                            {
                                                totalScores > -1 ?
                                                    <span>
                                                        Total Score: &nbsp; &nbsp;  &nbsp;     {totalScores}
                                                    </span>
                                                    :
                                                    null
                                            }
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                color="primary"
                                                onClick={editHandler}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div >

            </Drawer >
        </>
    );
}

export default Checker;