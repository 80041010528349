// Global Imports
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";

// Design Imports
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import ChatIcon from "@material-ui/icons/Chat";
import CallIcon from "@material-ui/icons/Call";
import CancelIcon from "@material-ui/icons/Cancel";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import SendIcon from "@material-ui/icons/Send";
import { PermPhoneMsg } from "@material-ui/icons";
import "react-phone-number-input/style.css";

const courseMessages = [
    "course message 1",
    "course message 2",
    "course message 3",
];

const videoMessages = ["video message 1", "video message 2", "video message 3"];
const homeMessages = ["home message 1", "home message 2", "home message 3"];
const paymentMessages = [
    "payment message 1",
    "payment message 2",
    "payment message 3",
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "2.5rem",
        height: "2.5rem",
        borderRadius: "10px",
    },
    paper: {
        background: "transparent",
    },
    menuItem: {
        width: "3rem",
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1rem",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "20rem",
        width: "20rem",
        margin: "auto",
    },
    typography: {
        padding: theme.spacing(2),
    },
}));

const MsgBot = ({ msg, setMsg }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [callModal, setCallModal] = useState(false);
    const [phoneNo, setPhoneNo] = useState("");
    const msgElRef = useRef(null);
    const anchorRef = useRef(null);
    const invalidPhoneRef = useRef(null);
    const history = useHistory();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    //   const openCallModal = (event) => {
    //     handleClose(event);
    //     setCallModal(true);
    //   };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const savePhoneNo = (e) => {
        if (!phoneNo) {
            e.preventDefault();
            return;
        }
        //if phone no. provided is invalid
        if (!isValidPhoneNumber(phoneNo)) {
            invalidPhoneRef.current.style.display = "block";
            setTimeout(() => {
                invalidPhoneRef.current.style.display = "none";
            }, 2500);
            return;
        }

        //save valid phone no. in firebase
        // do this immediately after storing data ========> setPhoneNo('');

        setCallModal(false);

        //success response
        return Swal.fire({
            title: "Thank you!",
            text: "We will get in touch soon",
            icon: "success",
            timer: 2000,
        });
    };

    return (
        <>
            {
                <div className="msg-bot" ref={msgElRef} style={{ zIndex: "1000" }}>
                    <Button
                        id="msg-bot"
                        ref={anchorRef}
                        onClick={handleToggle}
                        className={classes.root}
                    >
                        {open ? (
                            <CancelIcon
                                style={{
                                    width: "3rem",
                                    height: "3rem",
                                    color: "#AD2831",
                                    background: "#f9f9f9",
                                    backgoundShadow: "2px 2px 2px 2px ",
                                    borderRadius: "10px",
                                    boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
                                }}
                            />
                        ) : (
                            <PermPhoneMsg
                                style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                    color: "#AD2831",
                                    background: "#f9f9f9",
                                    backgoundShadow: "2px 2px 2px 2px ",
                                    borderRadius: "10px",
                                    boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
                                }}
                            />
                        )}
                    </Button>
                    <Popper
                        open={msg}
                        anchorEl={anchorRef.current}
                        placement={"left"}
                        style={{ position: "inherit" }}
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                    <Typography className={classes.typography}>
                                        {history.location.pathname === "/"
                                            ? `${homeMessages[
                                            Math.floor(Math.random() * homeMessages.length)
                                            ]
                                            }`
                                            : history.location.pathname === "/coursepage"
                                                ? `${courseMessages[
                                                Math.floor(Math.random() * courseMessages.length)
                                                ]
                                                }`
                                                : history.location.pathname === "/payment"
                                                    ? `${paymentMessages[
                                                    Math.floor(Math.random() * paymentMessages.length)
                                                    ]
                                                    }`
                                                    : history.location.pathname.includes("/video")
                                                        ? `${videoMessages[
                                                        Math.floor(Math.random() * videoMessages.length)
                                                        ]
                                                        }`
                                                        : "Welcome to Recruiting Monk!!"}
                                    </Typography>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>

                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <div className={classes.paper}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            style={{ background: "#f9f9f9", borderRadius: "100px" }}
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <Tooltip title="Contact" placement="left">
                                                <MenuItem
                                                    style={{ width: "2rem" }}
                                                    onClick={() =>
                                                        window.open(
                                                            "https://forms.gle/7ygicS77MKcDZFPH8",
                                                            "_blank"
                                                        )
                                                    }
                                                    className={classes.menuItem}
                                                >
                                                    <CallIcon
                                                        style={{
                                                            color: "#AD2831",
                                                            width: "2.5rem",
                                                            height: "2.5rem",
                                                        }}
                                                    />
                                                </MenuItem>
                                            </Tooltip>
                                            <Tooltip title="Talk to Us" placement="left">
                                                <MenuItem
                                                    style={{ width: "2rem" }}
                                                    onClick={() =>
                                                        window.open(
                                                            "https://api.whatsapp.com/message/XJKMT6EAGWRHI1",
                                                            "_blank"
                                                        )
                                                    }
                                                    className={classes.menuItem}
                                                >
                                                    <ChatIcon
                                                        style={{
                                                            color: "#AD2831",
                                                            width: "2.5rem",
                                                            height: "2.5rem",
                                                        }}
                                                    />
                                                </MenuItem>
                                            </Tooltip>
                                        </MenuList>
                                    </ClickAwayListener>
                                </div>
                            </Grow>
                        )}
                    </Popper>
                    <div className="flex flex-row items-center justify-between mb-2">
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={callModal}
                            onClose={() => setCallModal(false)}
                            closeAfterTransition
                            disableAutoFocus={true}
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade
                                in={callModal}
                                className="glass-6 flex flex-col items-center p-4 w-5/6 md:w-2/4 ring-0 border-0"
                            >
                                <div
                                    className="p-4 flex items-center"
                                    style={{
                                        background: "#FFFFFF",
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        backgroundImage:
                                            "url('https://recruitingmonk.com/wp-content/uploads/2020/03/Profile-back.png)'",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                    }}
                                >
                                    <div className="phone-input">
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            value={phoneNo}
                                            onChange={setPhoneNo}
                                        />
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={savePhoneNo}
                                            style={{ width: "5%", background: "#AD2831" }}
                                        >
                                            <SendIcon style={{ color: "white" }} />
                                        </Button>
                                    </div>
                                    <span
                                        ref={invalidPhoneRef}
                                        style={{
                                            color: "#AD2831",
                                            display: "none",
                                            position: "absolute",
                                            bottom: "2rem",
                                        }}
                                    >
                                        Enter a valid Phone Number
                                    </span>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                </div>
            }
        </>
    );
}

// const CustomInput = () => {
//   return <input type="text" />;
// };

export default MsgBot;
