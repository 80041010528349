import React from "react";
import "./StoryTelling.css";
import LandingHeader from "../../component/storyTelling/LandingHeader"
import LandingAbout from "../../component/storyTelling/LandingAbout";
import LandingCourse from "../../component/storyTelling/LandingCourse";
import LandingReview from "../../component/storyTelling/LandingReview";
import LandingQuestion from "../../component/storyTelling/LandingQuestion";
import LandingCourseBanner from "../../component/storyTelling/LandingCourseBanner";
import LandingFooter from "../../component/storyTelling/LandingFooter";

const StoryTelling = () => {
  return (
    <>
      <div className="page-wrapper">
        <div className="global-styles w-embed"></div>
        <main className="main-wrapper">
          <LandingHeader />
          <LandingAbout />
          <LandingCourse />
          <LandingReview />
          <LandingQuestion />
          <LandingFooter />
        </main>
      </div>
    </>
  );
};

export default StoryTelling;
