import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import DoubtAnswer from "./DoubtAnswer";

import { CircularProgress } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import ReplyRoundedIcon from "@material-ui/icons/ReplyRounded";
import { Button, TextField, Divider } from "@material-ui/core";
import { Grid, Typography, Hidden } from "@material-ui/core";
import Swal from "sweetalert2";

const DoubtManagement = (props) => {
    const [doubt, setDoubt] = useState([]);
    const [doubtLoading, setDoubtLoading] = useState(true);
    const [replyDiv, setReplyDiv] = useState(false);
    const [doubtReply, setDoubtReply] = useState("");
    const [answers, setAnswers] = useState([]);
    const [added, setAdded] = useState(false);
    const [addedReply, setAddedReply] = useState({});
    const history = useHistory();

    useEffect(() => {
        getDoubt();
    }, [props.doubtID]);

    const handleReplyDiv = () => {
        setReplyDiv(!replyDiv);
    }

    const handleDoubtReply = (e) => {
        setDoubtReply(e.target.value);
    }







    const getDoubt = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setDoubtLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/doubts/get/doubt`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    },
                    body: JSON.stringify({
                        doubtID: props.doubtID
                    })
                });
                const selectedDoubtData = (await response.json());
                if (selectedDoubtData.apiStatus && selectedDoubtData.apiStatus !== "SUCCESS" || selectedDoubtData.apiStatus !== "success" || selectedDoubtData.apiStatus !== "Success") {
                    setDoubt({});
                }
                setDoubt(selectedDoubtData.data);
                setAnswers(selectedDoubtData.data.answers)
                setDoubtLoading(false);
            } catch (error) {
                console.log("Doubt cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }

    const postDoubtReply = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/doubts/post/reply`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    },
                    body: JSON.stringify({
                        doubtID: props.doubtID,
                        answer: doubtReply
                    })
                });
                const selectedDoubtData = await response.json();
                if (selectedDoubtData.apiStatus && selectedDoubtData.apiStatus != "SUCCESS" && selectedDoubtData.apiStatus != "Success" && selectedDoubtData.apiStatus != "success") {
                    history.push('/courses')
                }
                Swal.fire({
                    title: "Your Reply is Posted!",
                    icon: "success"
                });
                var d1 = new Date();
                setAddedReply({
                    name: selectedDoubtData.data.name,
                    profilePhoto: selectedDoubtData.data.profilePhoto,
                    answer: doubtReply,
                    createdAt: d1
                });
                setAdded(true);
                setDoubtReply("");
            } catch (error) {
                console.log(error)
            }
        } else {
            history.push("/home");
        }

    }




    return (
        <>
            {
                doubtLoading ?
                    <CircularProgress />
                    :
                    <>
                        <Grid container className="text-center">
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    align="center"
                                    style={{
                                        fontSize: "20px",
                                        color: "#6495ED",
                                        fontWeight: "bold",
                                        paddingTop: "2px",
                                        paddingBottom: "2px"
                                    }}
                                >
                                    <span style={{ color: "#ad2831" }}>
                                        Course: &nbsp;&nbsp;&nbsp;
                                    </span>
                                    {doubt?.courseName}
                                </Typography>

                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <Typography
                                    align="center"
                                    style={{
                                        fontSize: "20px",
                                        color: "#6495ED",
                                        fontWeight: "bold",
                                        paddingTop: "2px",
                                        paddingBottom: "2px"
                                    }}
                                >
                                    <span style={{ color: "#ad2831" }}>
                                        Module: &nbsp;&nbsp;&nbsp;
                                    </span>
                                    {doubt?.moduleTitle}
                                </Typography>

                            </Grid>
                        </Grid>
                        <div className="ques-div relative p-8">
                            <Avatar
                                style={{ marginRight: "15px" }}
                                alt={doubt.name || "John Doe"}
                                src={
                                    doubt.profilePhoto ||
                                    "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
                                }
                            />
                            <div>
                                <p style={{ marginBottom: "10px" }}>
                                    <b>{doubt.doubtTxt}</b>
                                </p>
                                <p className="caption">
                                    Posted by: {doubt?.name?.substr(0, doubt?.name?.indexOf(" "))}
                                </p>
                            </div>
                        </div>
                        <div align="right">
                            <Button onClick={handleReplyDiv}>
                                <ReplyRoundedIcon
                                    fontSize="small"
                                    style={{ color: "#707070", cursor: "pointer" }}
                                />
                                <span style={{ marginLeft: "5px" }}>Reply</span>
                            </Button>
                        </div>
                        {
                            replyDiv ?
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sm={9} md={9}>
                                            <div className="Reply-container" >
                                                <TextField fullWidth multiline
                                                    value={doubtReply}
                                                    onChange={handleDoubtReply}
                                                    maxRows={2} className="Reply-field"
                                                    label="Reply" placeholder="Type Your Reply Here"
                                                    variant="outlined" />
                                            </div>
                                        </Grid>
                                        <Hidden only={['lg', 'md', 'sm', 'xl']}><Grid item xs={9}></Grid></Hidden>
                                        <Grid item xs={3} sm={3} md={3}>
                                            <div style={{ paddingTop: '20px' }} className="Reply-container-buttons">
                                                <Button size="small" variant="contained" className="Submit-Reply"
                                                    disabled={doubtReply.length === 0 ? true : false}
                                                    onClick={postDoubtReply}
                                                >Reply</Button>
                                            </div>
                                        </Grid>
                                        <Grid container style={{ marginLeft: "15px" }}>
                                            {
                                                answers.length > 0 ?
                                                    <Grid item xs={12} className="replies-box">
                                                        {answers?.map((answer, i) => {
                                                            return (
                                                                <DoubtAnswer ansid={answer._id} key={i} />
                                                            )
                                                        })
                                                        }

                                                        {
                                                            added ?
                                                                <DoubtAnswer answer={addedReply} />
                                                                :
                                                                null
                                                        }
                                                    </Grid>
                                                    :
                                                    added ?
                                                        <DoubtAnswer answer={addedReply} />
                                                        :
                                                        <Grid item xs={12} className="py-4" align="center">
                                                            <p>No Replies Here yet!!</p>
                                                            <Divider />
                                                        </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }

                    </>


            }
        </>
    );
}

export default DoubtManagement;