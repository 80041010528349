// Global Imports
import React from "react";

const Media = (props) => {
    return (
        <>
            <div className="md:col-span-2 md:ml-10 order-first md:order-none">
                <div className="flex flex-col gap-2">
                    {
                        (props.video !== undefined && props.video !== null && props.video!=="") ?
                            <div style={{ width: "inherit", height: "435px" }}>
                                <video
                                    style={{
                                        border: "2px solid lightgrey",
                                        borderRadius: "10px",
                                        width: "-webkit-fill-available",

                                    }}
                                    controls
                                    controlsList="nodownload"
                                    src={props.video}
                                    className="object-contain h-full"
                                />
                            </div>
                            : 
                            <div style={{ width: "inherit", height: "435px" }}>
                                <img
                                    style={{
                                        border: "2px solid lightgrey",
                                        borderRadius: "10px",
                                        width: "-webkit-fill-available",

                                    }}
                                    controls
                                    controlsList="nodownload"
                                    src={props.image}
                                    alt="course preview"
                                    className="object-contain h-full"
                                />
                            </div>
                    }

                </div>
            </div>
        </>

    );
}

export default Media;