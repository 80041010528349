import React, { useState } from "react";
import styles from "./Sessions.module.css";
import ReactPlayer from "react-player";

const Sessions = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hovered card

  const events = [
    {
      video: `https://player.vimeo.com/video/1002707875?h=ecbfc05809&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write`,
      title: `“Good Recruiters don't have a Boolean problem, they’ve a DATA Problem, Organizing the DATA”`,
    },

    {
      video: `https://player.vimeo.com/video/1002708881?h=64b8f352f0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write`,
      title: `“This age-old sourcing technique is least spoken in today’s AI ERA. But, this needs to be resurrected again”`,
    },
    {
      video: `https://player.vimeo.com/video/1002709845?h=6d360e0cc0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write`,
      title: `“How to perform "Data Classifications" while sourcing on LinkedIn”`,
    },
    {
      video: `https://player.vimeo.com/video/1002710517?h=eef658dfde&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write`,
      title: `“How do you probe your candidates? By asking them, rate of 10 or how many years of exp in a skill?
  ”`,
    },
    {
      video: `https://player.vimeo.com/video/1002712150?h=18a6a225a5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write`,
      title: `“How to spot public communities, resume databases which can be leveraged for Sourcing?
  ”`,
    },

    {
      video: `https://player.vimeo.com/video/1002712848?h=e06d99434b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write`,
      title: `“Calculating TOFU (top of the funnel) & MOFU (Middle of the Funnel)
  ”`,
    },
    {
      video: `https://player.vimeo.com/video/1002713803?h=450baf9f78&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write`,
      title: `“Myth vs Fact : Only 2-3 pages have quality profiles on Naukri
  ”`,
    },
    {
      video:`https://player.vimeo.com/video/1002711346?h=6ad23f1e7b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`,
      title:"Candidate Pitch Demo"
    }
  ];

  return (
    <div className={styles.events_container}>
      <div className={styles.events_subcontainer}>
        <div className={styles.events_card_container}>
          {events.map((data, index) => (
            <div
              className={styles.events_card}
              key={index}
              onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
              onMouseLeave={() => setHoveredIndex(null)} // Reset hovered index
            >
              <div
                className={`${styles.events_card_heading} ${
                  hoveredIndex === index ? styles.full_heading : ""
                }`}
              >
                {data.title}
              </div>
              <div className={styles.events_card_video}>
                <div className="aspect-ratio-box">
                  <ReactPlayer
                    url={data.video}
                    className="react-player"
                    width="100%"
                    height={`${index === 7 ? "200px" : "100%"}`}
                    controls
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sessions;
