// Global Imports 
import React from "react"

// Other File Imports

// Design Imports 



const Synopsis = (props) => {
    return (
        <>
            <div style={{ color: "#ad2831" }} className="mb-10 md:col-span-4 font-bold text-3xl order-first md:order-none">
                {props.title}
            </div>
            <p className="w-full font-medium text-2xl mb-4">
                Course Syllabus Synopsis
            </p>
            <p className="w-full text-sm mb-4">{props.desc}</p>
        </>
    );
}

export default Synopsis;