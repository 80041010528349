import React from "react";

const TasksEmpty = () => {
    return (
        <>
            <div className="flex justify-between px-4 w-full">
                <div className="text-lg text-gray-600">
                    -- No Task Attempted --
                </div>
            </div>
        </>
    );
}

export default TasksEmpty;