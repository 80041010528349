import React from "react";
import styles from "./TrainerPosts.module.css";
import styles2 from "./Events.module.css";

const TrainerPosts = () => {
  const screenshot = [
    {
      image: "./testimonial/trainer/linkedinTestimonial1.jpg",
      link: "https://www.linkedin.com/posts/ashfaqahmedhr_hld-sourcing-activity-7229422652107411456-iDUP?utm_source=share&utm_medium=member_desktop",
    },
    {
      image: "./testimonial/trainer/linkedinTestimonial2.jpg",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7155861382565875712?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A7155861382565875712%2C7155883787887767552%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287155883787887767552%2Curn%3Ali%3Aactivity%3A7155861382565875712%29",
    },
    {
      image: "./testimonial/trainer/linkedinTestimonial3.jpg",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7138405516787027968/",
    },
    {
      image: "./testimonial/trainer/linkedinTestimonial4.jpg",
      link: "https://www.linkedin.com/posts/ashfaqahmedhr_recruitingmonk-activity-7188478921544187905-TxLm?utm_source=share&utm_medium=member_desktop",
    },
    {
      image: "./testimonial/trainer/linkedinTestimonial5.jpg",
      link: "https://www.linkedin.com/posts/ashfaqahmedhr_recruitingmonk-activity-7224714257697759232-zJwn?utm_source=share&utm_medium=member_desktop",
    },
    {
      image: "./testimonial/trainer/linkedinTestimonial6.jpg",
      link: "https://www.linkedin.com/posts/ashfaqahmedhr_recruitingmonk-post815-activity-7176520500385382402-_OjT?utm_source=share&utm_medium=member_desktop",
    },
  ];

  return (
    <div className={styles2.events_container}>
      <div className={styles2.events_subcontainer}>
        <div className={styles2.events_subheading}>
          Frameworks & Principles{" "}
        </div>
        <div className={styles2.events_info}>
          Ashfaq builds high level frameworks & principles around Persona and
          data principles of sourcing. These frameworks bring more authority on
          your sourcing, minimizes biases & transforms you from just searching
          to talking to search engines
        </div>
        <div className={styles.trainer_testimonial_container}>
          {screenshot.map((data, key) =>
            key < 3 ? (
              <a
                className={styles.trainer_testimonial_card}
                key={key}
                href={data.link}
                target="_blank"
              >
                <img
                  src={data.image}
                  alt=""
                  className={styles.screenshot_image}
                />
              </a>
            ) : (
              <a
                className={styles.trainer_testimonial_card_horizontal}
                style = {{width:`${key === 3 ? "100%" : ""}`}}
                key={key}
                href={data.link}
                target="_blank"
              >
                <img
                  src={data.image}
                  alt=""
                  className={styles.screenshot_image}
                />
              </a>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainerPosts;
