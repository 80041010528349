import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Grid, LinearProgress, Typography, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

const CertificateCard = (props) => {

    const [loading, setLoading] = useState([]);
    const [certificateData, setCertificateData] = useState([]);
    const [isCredValid, setIsCredValid] = useState(false);

    useEffect(() => {
        getCertificate(props?.cred);
    }, []);


    const isNumeric = (num) => {
        return !isNaN(num)
    }

    const getCertificate = async (credID) => {
        setLoading(true);
        const credArray = credID.split('-');
        // credArray[0] = "RMK" -> constant,
        // credArray[1] = "smc" -> course name initials
        // credArray[2] = "9090" -> certificate count of the course + 1 
        // credArray[3] = "abcd" -> random string
        // credArray[4] = "aaa" -> string maintaining count above 9999 

        const courseSlugArray = props?.courseSlug.split('-');
        var courseInitials = "";
        for (let i = 0; i < courseSlugArray.length; i++) {
            courseInitials += courseSlugArray[i][0];
        }

        if (credArray[0] === "RMK" && credArray[1] === courseInitials && isNumeric(credArray[2]) && !isNumeric(credArray[3]) && credArray[3].length === 4 && !isNumeric(credArray[4]) && credArray[4].length === 3) {
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/certificate/validate/credential/${credID}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
                const validate = (await response.json());
                if (validate.apiStatus && validate.apiStatus != "SUCCESS" && validate.apiStatus != "Success" && validate.apiStatus != "success") {
                    return false;
                }
                setCertificateData(validate?.data);
                setIsCredValid(true);
                setLoading(false);
            } catch (error) {
                console.log("Error in Validating Credential ID", error);
            }
        } else {
            console.log("Invalid Cred ID");
        }
    }

    return (
        <>
            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    <>

                        <Grid item xs={12} sm={6} md={4} lg={3} className="flex justify-center my-5">
                            <Link to={`/certificate/${props?.courseSlug}?credID=${props?.cred}`} target="_blank"><Card className="card">
                                <CardMedia
                                    component="img"
                                    style={{ width: "280px", height: "360px", borderRadius: "10px" }}
                                    image={certificateData?.certificateUrl}
                                    alt={certificateData?.name}
                                />
                                <CardContent>
                                    <Typography gutterBottom style={{ color: "#ad2831", fontWeight: "bold" }} component="div">
                                        {certificateData?.title}
                                    </Typography>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }} color="text.secondary">
                                        Credential ID : {certificateData?.credentialID}
                                    </Typography>
                                </CardContent>

                            </Card>
                            </Link>
                        </Grid>
                    </>
            }
        </>
    );
}

export default CertificateCard;