import React, { useState } from "react";

import { auth } from "../../firebase";

import { Avatar } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const DoubtAnswer = (props) => {
    const ansid = props.ansid;
    const [answer, setAnswer] = useState([]);
    const [answerLoading, setAnswerLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        console.log(props);
        if (props.answer !== undefined && props.answer !== null) {
            setAnswer(props.answer);
        } else {
            getAnswer();
        }
    }, [ansid, props]);

    const getAnswer = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setAnswerLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/doubts/get/answer`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    },
                    body: JSON.stringify({
                        ans_id: props.ansid
                    })
                });
                const answerData = (await response.json());
                console.log(answerData);
                if (answerData.apiStatus && answerData.apiStatus != "SUCCESS" && answerData.apiStatus != "Success" && answerData.apiStatus != "success") {
                    history.push('/courses')
                }
                setAnswer(answerData.data);
                setAnswerLoading(false);
            } catch (error) {
                console.log(error)
            }
        } else {
            history.push("/home");
        }
    }

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const updated = new Date(answer?.createdAt);

    return (
        <>
            <div>
                <div className="replies-div">
                    <Avatar
                        style={{ marginRight: "15px" }}
                        alt={answer?.name}
                        src={
                            answer?.profilePhoto ||
                            "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
                        }
                    />
                    <div>
                        <p style={{ color: "blue", marginBottom: "10px" }}>
                            {answer?.name}
                        </p>
                        <p className="caption">
                            <i>Added {updated?.toLocaleDateString("en-us", options)}</i>
                        </p>
                    </div>
                </div>
                <div>
                    <p id="ans">
                        {answer?.answer}
                    </p>
                </div>
                <Divider />
            </div>
        </>
    );
}

export default DoubtAnswer;

