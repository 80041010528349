import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import { Grid, CircularProgress, LinearProgress } from "@material-ui/core";
import Checker from "../Checker";


const TaskResponses = (props) => {

    const [loading, setLoading] = useState(true);
    const [progressData, setProgressData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getProgress();
    }, [])


    const getProgress = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/tests/get/progress`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    },
                    body: JSON.stringify({
                        progressID: props?.task?.progressID
                    })
                });
                const selectedTestData = (await response.json());
                if (selectedTestData.apiStatus !== "SUCCESS" && selectedTestData.apiStatus !== "success" && selectedTestData.apiStatus !== "Success") {
                    history.push("/admin/users");
                }
                setProgressData(selectedTestData?.data);
                setLoading(false);
            } catch (error) {
                console.log("Tests cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }

    return (
        <>
            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    progressData?.completed ?
                        <Grid container className="flex items-center p-1 border-2 bg-gray-100 hover:bg-gray-200 font-medium text-center">
                            <Grid item xs={12} sm={6} md={2} className="py-1">
                                <CourseName slug={progressData?.courseSlug} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2} className="py-1">
                                <ModuleTitle slug={progressData?.courseSlug} module={progressData?.moduleNo} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="py-1">
                                <p style={{ fontSize: "14px" }}>
                                    Attempted:&nbsp;
                                    {progressData?.attempted.toFixed(0)}
                                    %
                                </p>
                                <LinearProgress
                                    variant="determinate"
                                    value={progressData?.attempted}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} className="py-1">
                                {format(new Date(progressData?.updatedAt), 'dd/MM/yyyy hh:mm:ss')}
                            </Grid>
                            <Grid item xs={6} sm={4} md={1} className="py-1">
                                <Checker data={progressData} />
                            </Grid>
                            <Grid item xs={6} sm={4} md={1} className="py-1">
                                Marks: {progressData?.totalScores < 0 ? "TBD*" : progressData?.totalScores}
                            </Grid>
                        </Grid>
                        :
                        null
            }
        </>
    );
}

const CourseName = (props) => {
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        getCourseData();
    }, []);

    const getCourseData = async () => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${props?.slug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const data = (await response.json());
            setCourseData(data.data);
        } catch (error) {
            console.log("Featured Courses cannot be fetched.", error);
        }
    }

    return (
        <>
            {courseData?.title}
        </>
    );
}

const ModuleTitle = (props) => {
    const [moduleData, setModuleData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getModuleData();
    }, []);

    const getModuleData = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/courses/curriculums/${props?.slug}?moduleNo=${props?.module}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const data = (await response.json());
                setModuleData(data.data);
            } catch (error) {
                console.log("Featured Courses cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    return (
        <>
            {moduleData?.title}
        </>
    );
}


export default TaskResponses;