// Global Imports
import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
// import { useTable, useSortBy } from 'react-table';


// Other File Imports 
import {
    COURSESLIST,
    CURRICULUMLIST,
    DOUBTLIST,
    TESTLIST
} from "./Columns";

import {
    CircularProgress,
    Grid,
    InputLabel,
    NativeSelect,
    Button
} from "@material-ui/core";
import Table from "./Table";
// import { PinDropSharp, PriorityHighSharp } from "@material-ui/icons";


const Courses = () => {
    // Design related
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [perPage, setPerPage] = useState(10);

    // Data Fetching
    const [courses, setCourses] = useState([]);
    const [tableData, setTableData] = useState(courses);
    const [curriculums, setCurriculums] = useState([]);
    const [doubts, setDoubts] = useState([]);
    const [tests, setTests] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);

    const [course, setCourse] = useState("");
    const [curriculum, setCurriculum] = useState("");
    const [doubt, setDoubt] = useState("");
    const { path } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        getCourses();
    }, [pageNumber]);

    const getCourses = async () => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/courses/list?perPage=${perPage + 1}&pageNumber=${pageNumber}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const courseData = (await response.json());
                setCourses(courseData.data);
                setTableData(courseData.data);
                setTableColumns(COURSESLIST);
                if (courseData.data.length > perPage) {
                    setPageNumber(pageNumber + 1);
                    setPerPage(perPage);
                }
                setLoading(false);
            } catch (error) {
                console.log("Courses cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    const getCurriculumData = async (courseSlug) => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/courses/curriculums/${courseSlug}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });
                const selectedCurriculumData = (await response.json());
                if (selectedCurriculumData.data === null) {
                    setCurriculums([]);
                    setTableData(courses);
                    setTableColumns(COURSESLIST);
                }
                setCurriculums(selectedCurriculumData.data.modules);
                setTableData(selectedCurriculumData.data.modules);
                setTableColumns(CURRICULUMLIST);
                console.log(selectedCurriculumData.data.modules);
                setLoading(false);
            } catch (error) {
                console.log("Curriculum cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    const getDoubts = async (courseSlug, moduleNo) => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/doubts/${courseSlug}?moduleNo=${moduleNo}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization" : adminToken
                    }
                });
                const selectedDoubtData = (await response.json());
                if (selectedDoubtData === null) {
                    setDoubts([]);
                    setTableData(curriculums);
                    setTableColumns(CURRICULUMLIST);
                }
                setDoubts(selectedDoubtData.data);
                setTableData(selectedDoubtData.data);
                setTableColumns(DOUBTLIST);
                console.log(selectedDoubtData);
                setLoading(false);
            } catch (error) {
                console.log("Doubts cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    const getTestAttempts = async (courseSlug, moduleNo) => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;
            try {
                setLoading(true);
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/tests/${courseSlug}?moduleNo=${moduleNo}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization":adminToken
                    }
                });
                const selectedTestData = (await response.json());
                if (selectedTestData === null) {
                    setTests([]);
                    setTableData(curriculums);
                    setTableColumns(CURRICULUMLIST)
                }
                setTests(selectedTestData.data);
                setTableData(selectedTestData.data);
                setTableColumns(TESTLIST);
                console.log(selectedTestData);
                setLoading(false);
            } catch (error) {
                console.log("Tests cannot be fetched.", error);
            }
        } else {
            history.push("/home");
        }
    }


    const handleCourse = (e) => {
        setCourse(e.target.value);
        if (e.target.value === "") {
            getCourses();
        } else {
            getCurriculumData(e.target.value);
        }
    }


    const handleCurriculum = (e) => {
        setCurriculum(e.target.value);
        if (e.target.value === "") {
            getCurriculumData(course);
        } else {
            let arguementArray = e.target.value.split("@");
            if (arguementArray[1] === "content") {
                getDoubts(course, arguementArray[0]);
            } else if (arguementArray[1] === "task") {
                getTestAttempts(course, arguementArray[0]);
            }
        }
    }

    // const handleDoubt = (e) => {
    //     setDoubt(e.target.value);
    // }


    return (
        <>
            <Grid container className='top'>
                <Grid item xs={6} sm={4} md={3} style={{ padding: '20px' }}>
                    <InputLabel htmlFor="course">Course</InputLabel>
                    <NativeSelect
                        id="course"
                        name="course"
                        value={course}
                        onChange={handleCourse}>
                        <option value="">--- Select Course ---</option>
                        {
                            courses?.map((courseData, i) => {
                                return (
                                    <option key={i} value={courseData.courseSlug}>{courseData.title}</option>
                                );
                            })
                        }
                    </NativeSelect>
                </Grid>

                {
                    course !== "" ?
                        <>
                            <Grid item xs={6} sm={4} md={3} style={{ padding: '20px' }}>
                                <InputLabel htmlFor="curriculum">Curriculum</InputLabel>
                                <NativeSelect
                                    id="curriculum"
                                    name="curriculum"
                                    value={curriculum}
                                    onChange={handleCurriculum}>
                                    <option value="">--- Select Curriculum ---</option>
                                    {
                                        curriculums?.map((curriculumData, i) => {
                                            return (
                                                <option key={i} value={`${curriculumData?.moduleNo}@${curriculumData?.type}`}>{curriculumData?.title}</option>
                                            );
                                        })
                                    }
                                </NativeSelect>
                            </Grid>

                            {/* {
                                curriculum !== "" ?
                                    <Grid item xs={6} sm={4} md={3} style={{ padding: '20px' }}>
                                        <InputLabel htmlFor="test">Doubts</InputLabel>
                                        <NativeSelect
                                            id="test"
                                            name="test"
                                            value={doubt}
                                            onChange={handleDoubt}>
                                            <option value="">--- Select Doubt ---</option>

                                            {
                                                doubts.map((doubtData, i) => {
                                                    return (
                                                        <option key={i} value={doubtData.uid}>{doubtData.name}</option>
                                                    );
                                                })
                                            }
                                        </NativeSelect>
                                    </Grid>
                                    :
                                    null
                            } */}


                        </>
                        : null
                }



                <Grid item xs={6} sm={4} md={3} style={{ padding: '20px' }}>
                    <p>
                        <b>Total Entries: {tableData.length}</b>
                    </p>
                </Grid>
            </Grid>
            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            style={{ overflowX: 'auto', marginTop: '20px', padding: '10px' }}>
                            {
                                tableData.length != 0 && tableColumns.length != 0 ?
                                    <Table tableColumns={tableColumns} tableData={tableData} />
                                    :
                                    null
                            }

                        </Grid>
                    </Grid>
            }
        </>
    )
}

export default Courses;