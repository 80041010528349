import React, { useState } from "react";

const LandingQuestion = () => {
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);

  return (
    <section className="section-faq wf-section">
      <div className="padding-global padding-section-large">
        <div className="container-medium">
          <div className="faq_wrapper">
            <h2 className="text-align-center">Frequently Asked Questions</h2>
            <div className="faq_content-wrapper">
              <div
                data-w-id="b96b4542-3047-72a1-60b1-fa93b61cb138"
                className="accordion-item-wrapper v6"
              >
                <div className="accordion-content-wrapper width-660px">
                  <div className="accordion-header">
                    <h3 className="accordion-title">Who is the trainer?</h3>
                  </div>
                  <div
                    style={{
                      height: question1 ? "190px" : "0px",
                      transition: "height 0.3s",
                      WebkitTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: question1 ? "" : 0,
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Ashfaq ahmed, he is the founder of RecruitingMonk. He has
                      trained/consulted more than 10,000+ Recruiters across
                      corporates, startups and agencies. He writes on linkedin
                      everyday about TA. Visit his linkedin profile{" "}
                      <a
                        href="https://www.linkedin.com/in/ashfaqahmedhr/"
                        target="_blank"
                      >
                        right here
                      </a>
                    </p>
                  </div>
                </div>
                <div className="accordion-side right-side">
                  <div
                    onClick={() => setQuestion1(!question1)}
                    className="btn-circle-secondary small accordion-btn w-inline-block"
                    style={{ backgroundColor: question1 ? "#f99b37" : "" }}
                  >
                    <div className="accordion-btn-line vertical"></div>
                    <div className="accordion-btn-line horizontal"></div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="09ecc7fb-c901-1ca9-b971-0c6b7af7d86c"
                className="accordion-item-wrapper v6"
              >
                <div className="accordion-content-wrapper width-660px">
                  <div className="accordion-header">
                    <h3 className="accordion-title">Is it a live session?</h3>
                  </div>
                  <div
                    style={{
                      height: question2 ? "120px" : "0px",
                      transition: "height 0.3s",
                      WebkitTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: question2 ? "" : 0,
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Yes, it will be a live webinar
                    </p>
                  </div>
                </div>
                <div className="accordion-side right-side">
                  <div
                    onClick={() => setQuestion2(!question2)}
                    className="btn-circle-secondary small accordion-btn w-inline-block"
                    style={{ backgroundColor: question2 ? "#f99b37" : "" }}
                  >
                    <div className="accordion-btn-line vertical"></div>
                    <div className="accordion-btn-line horizontal"></div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="ce07f836-e054-9e92-ac41-907507bcce09"
                className="accordion-item-wrapper v6"
              >
                <div className="accordion-content-wrapper width-660px">
                  <div className="accordion-header">
                    <h3 className="accordion-title">
                      Will I get a certificate after the session?
                    </h3>
                  </div>
                  <div
                    style={{
                      height: question3 ? "120px" : "0px",
                      transition: "height 0.3s",
                      WebkitTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: question3 ? "" : 0,
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Yes, after completing the session, you will get a
                      certificate.
                    </p>
                  </div>
                </div>
                <div className="accordion-side right-side">
                  <div
                    onClick={() => setQuestion3(!question3)}
                    className="btn-circle-secondary small accordion-btn w-inline-block"
                    style={{ backgroundColor: question3 ? "#f99b37" : "" }}
                  >
                    <div className="accordion-btn-line vertical"></div>
                    <div className="accordion-btn-line horizontal"></div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="cc25f66e-4f9a-3d0e-0861-7522ce979ea7"
                className="accordion-item-wrapper v6"
              >
                <div className="accordion-content-wrapper width-660px">
                  <div className="accordion-header">
                    <h3 className="accordion-title">Who can attend this?</h3>
                  </div>
                  <div
                    style={{
                      height: question4 ? "120px" : "0px",
                      transition: "height 0.3s",
                      WebkitTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      opacity: question4 ? "" : 0,
                    }}
                    className="acordion-body"
                  >
                    <div className="accordion-spacer"></div>
                    <p className="mg-bottom-0">
                      Anyone with atleast one year of experience in Talent
                      Acquisition
                    </p>
                  </div>
                </div>
                <div className="accordion-side right-side">
                  <div
                    onClick={() => setQuestion4(!question4)}
                    className="btn-circle-secondary small accordion-btn w-inline-block"
                    style={{ backgroundColor: question4 ? "#f99b37" : "" }}
                  >
                    <div className="accordion-btn-line vertical"></div>
                    <div className="accordion-btn-line horizontal"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingQuestion;
