import React from "react";
import styles from "./LearningInPrograme.module.css";

const LearningInPrograme = () => {
  const data = [
    {
      imageUrl: "/images/l-svg1.svg",
      text: "Ditch JDs & adopt Personas based hiring",
    },
    {
      imageUrl: "/images/l-svg2.svg",
      text: "Learn to spot & mitigate biases",
    },
    {
      imageUrl: "/images/l-svg3.svg",
      text: "Implement Bigdata concepts in sourcing",
    },
    {
      imageUrl: "/images/l-svg4.svg",
      text: "Storytelling in the Hiring Process",
    },
    {
      imageUrl: "/images/l-svg5.svg",
      text: "Engaging Candidates and Hiring Managers",
    },
    {
      imageUrl: "/images/l-svg6.svg",
      text: "Building Talent Intelligence Frameworks",
    },
  ];

  return (
    <div className={styles.learning_container}>
      <div className={styles.learning_subcontainer}>
        <div className={styles.learning_heading}>
          What will you learn in this program?
        </div>

        <div className={styles.learning_content}>
          {data.map((data, key) => (
            <div className={styles.learning_content_container} key={key}>
              <img
                src={data.imageUrl}
                alt=""
                className={styles.learning_content_image}
              />

              <div className={styles.content_text}>
                <p className={styles.content_heading}>Module {key + 1}</p>

                <p className={styles.subheading}>{data.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LearningInPrograme;
