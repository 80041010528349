// Global Imports
import React from "react";
import { Link } from "react-router-dom";

// Design Import 
import Card from "@material-ui/core/Card";
import { Avatar } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from "@material-ui/icons/LinkedIn";


const TestimonialCard = (props) => {

    return (

        <>
            <Card
                style={{
                    border: "2px solid #e6e6e6",
                    borderRadius: "16px",
                    minHeight:"220px"
                }}
                variant="outlined"
            >
                <CardContent>
                    <div
                        className="grid grid-cols-11"
                    >
                        <div className="col-span-2 items-center">
                            <Link to={{
                                pathname:
                                    props.data.testimonialUrl,
                            }}
                                target="_blank">
                                <Avatar
                                    src={props.data.testimonialUserImageUrl !== "" ? props.data.testimonialUserImageUrl : "https://firebasestorage.googleapis.com/v0/b/elearning-261cd.appspot.com/o/avatar-female.png?alt=media&token=4848fb01-5f6b-42b2-a45a-d9996ea445a2"}
                                    style={{
                                        width: "65px",
                                        height: "65px"
                                    }}
                                />
                            </Link>
                        </div>
                        <div className="col-span-1"></div>
                        <div className="col-span-8 items-center">
                            <Link to={{
                                pathname:
                                    props.data.testimonialUrl,
                            }}
                                target="_blank">
                                <Typography
                                    style={{
                                        fontWeight: "bold",
                                        color: "#ad2830",
                                        fontSize: "20px",
                                        fontFamily: "Roboto",
                                        lineHeight: "25px"
                                    }}
                                    variant="h6"
                                    component="h2"
                                >
                                    <LinkedInIcon
                                        style={{
                                            fill: "#2867B2",
                                            cursor: "pointer",
                                        }}
                                    />
                                    {props.data.testimonialUser}
                                </Typography>
                            </Link>
                            <Typography
                                style={{
                                    color: "#333333",
                                    fontSize: "14px",
                                    fontFamily: "Roboto",
                                    fontWeight: "400",
                                    lineHeight: "19.2px",
                                    marginTop: "5px"
                                }}
                            >
                                {props.data.testimonialUserTitle}
                                <br />@{props.data.testimonialUserCompany}
                            </Typography>
                        </div>
                    </div>

                    <div>
                        <p className="my-2 font-serif text-xs">
                            <span className="text-blue-900">"</span>
                            {props.data.testimonial}
                            <span className="text-blue-900">"</span>
                        </p>
                    </div>
                </CardContent>
            </Card>
        </>
    );
}

export default TestimonialCard;