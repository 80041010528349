// Global Imports
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// Design Imports
import {
    Typography,
    Button
} from "@material-ui/core";

const scrollToRef = (ref) => window.scrollTo(0, 0);

const FeaturedCard = (props) => {
    const [authorImage, setAuthorImage] = useState("");
    const [authorImageLoading, setAuthorImageLoading] = useState(false);
    const topRef = useRef(null);
    const executeScroll = () => scrollToRef(topRef);


    useEffect(() => {
        getAuthorImage();
    }, []);

    const getAuthorImage = async () => {
        try {
            setAuthorImageLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/author/${props.featured.authorID}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const author = (await response.json());
            if (!(author.apiStatus && author.apiStatus != "SUCCESS" && author.apiStatus != "Success" && author.apiStatus != "success")) {
                setAuthorImage(author.data.imageUrl);
                setAuthorImageLoading(false);
            }
        } catch (error) {
            console.log("Author Image Could Not be fetched.", error);
        }
    }


    return (
        <>
            <div className="p-2 md:w-1/4 sm:w-1/2">
                <div className="h-full border-2 border-gray-200 rounded-2xl overflow-hidden">
                    <img className="md:h-24 h-36 w-full object-cover object-center" src={props.featured.previewImageUrl} alt={props.featured.title} />
                    <div className="p-4 flex justify-between w-full">
                        <div>
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    color: "#ad2831",
                                    fontWeight: "bold",

                                }}
                            >
                                {props.featured.title}
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    color: "#000",
                                    fontWeight: "normal",

                                }}
                            >
                                taught by {props.featured.author}
                            </Typography>

                        </div>
                        {
                            authorImageLoading ?
                                <div
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%"
                                    }}
                                    className="h-48 w-48 bg-gray-400"
                                >

                                </div>

                                :
                                <img
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%"
                                    }}
                                    src={authorImage}
                                />
                        }

                    </div>
                    <div className="p-4">
                        <Typography
                            variant="subtitle2"
                            color="inherit"
                            style={{ marginTop: "30px" }}
                        >
                            {props.featured.previewTxt}
                        </Typography>
                        <p className="leading-relaxed my-3 font-bold">
                            {props.featured.price > 0 ?
                                ` 
                               ₹ ${props.featured.price}
                                `
                                :
                                <Link
                                    className="Freshers_page_Apply_for_free"
                                    to={{ pathname: props.featured.courseSlug == "agency-to-product-recruiter" ? "https://forms.gle/8FjZwbE4mqiiS44W8" : "https://bit.ly/rm-freshers-signup" }}
                                    target="_blank"
                                    onClick={executeScroll}
                                >
                                    <Button
                                        variant="contained"
                                        className="Freshers_page_Apply_for_free"
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#ad2830",
                                            marginTop: "10px",
                                        }}
                                    >
                                        {props.featured.btnTxt}
                                    </Button>
                                </Link>
                            }</p>
                        <div className="flex items-center flex-wrap ">
                            {
                                props.featured.courseSlug === "mini-diploma-for-fresh-grads" ?

                                    <Link className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0" to={{ pathname: "https://academy.recruitingmonk.com" }} target="_blank" onClick={executeScroll}>Learn More
                                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </Link>
                                    :

                                    <Link className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0" to={props.featured.courseSlug == "agency-to-product-recruiter"? "mini-diploma" :`/course/${props.featured.courseSlug}`} onClick={executeScroll}>Learn More
                                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeaturedCard;