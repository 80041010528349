import React from "react";
import styles from "./WorkshopFor.module.css";

const WorkshopFor = () => {
  const data = [
    "Recruiters with 1-20 years of experience in tech hiring",
    "Recruiters aiming to enhance their sourcing skills",
    "Professionals looking to leverage storytelling techniques",
    "Managers seeking to improve talent intelligence practices",
  ];

  return (
    <div className={styles.workshop_container}>
      <div className={styles.workshop_subcontainer}>
        <div className={styles.workshop_heading}>Who is this workshop for?</div>

        <div className={styles.workshop_content}>
          {data.map((data, key) => (
            <div key={key}>{data}</div>
          ))}
        </div>

        <div className={styles.buy_message_container_workshop}>
        <p className={styles.workshop_messageg_content}>Not sure about taking this quiz?</p>
        <button
        className={styles.join_button_option}
          onClick={() => window.open("https://quiz.recruitingmonk.com/", "_blank")}
          style={{ 
            border: "1.5px solid #191919"
          }}
        >
          Take Quiz
        </button>
      </div>
      </div>

    </div>
  );
};

export default WorkshopFor;
