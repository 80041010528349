import React, { useEffect, useState } from "react";
import VideosCard from "./VideosCard";
import "./videos.css";

const VideosGrid = (props) => {
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        setDatas(props.data);
    }, []);

    return (
        <>
            <section className="course-videos mb-10">
                {
                    datas?.map((data, i) => {
                        return (
                            <div key={i} className="bg-white flex flex-row flex-wrap p-2 my-3">
                                <div className="mx-auto w-5/6 shadow-inner">
                                    <VideosCard data={data} />
                                </div>
                            </div>
                        )
                    })
                }
            </section>
        </>
    );
}

export default VideosGrid;