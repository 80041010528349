import React, { useEffect, useState } from "react";

// Design Imports
import { Avatar, Grid, Typography } from "@material-ui/core";
import UserInfoLoading from "./loading/UserInfoLoading";



const UserInfo = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [profilePhoto, setProfilePhoto] = useState("");

    useEffect(() => {
        setName(props?.name);
        setEmail(props?.email);
        setProfilePhoto(props?.profilePhoto);
    }, [props]);

    return (
        <>
            <Grid container style={{ height: "max-content" }} className="jumb">
                <Grid item xs={12} md={4}>  
                    <Typography variant="h3" style={{ color: "#ad2831", fontWeight: "bold", padding: "20px" }}>
                        My Learning
                    </Typography>

                </Grid>
                {
                    name && name !== "" && email && email !== "" && profilePhoto && profilePhoto !== "" ? (
                        <Grid item xs={12} md={8} className="flex justify-end gap-2 items-center my-auto">
                            <p className="text-lg font-semibold text-primary">
                                {email} &nbsp;
                                {name}
                            </p>
                            <Avatar
                                style={{ marginRight: "15px" }}
                                alt={name}
                                src={
                                    profilePhoto ||
                                    "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
                                }
                            />
                        </Grid>
                    ) : <UserInfoLoading />
                }

            </Grid>
        </>
    );
}

export default UserInfo;