import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

// Other Components 
import Header from "../../component/global/Header";
import Synopsis from "../../component/courses/Synopsis";
import Footer from "../../component/global/Footer";
import Progress from "../../component/courses/Progress";
import Playlist from "../../component/courses/Playlist";
import Video from "../../component/courses/Video";
import Task from "../../component/courses/Task";
import Interaction from "../../component/courses/Interaction";
import SynopsisLoading from "../../component/courses/loading/Synopsis";
import ProgressLoading from "../../component/courses/loading/Progress";
import PlaylistLoading from "../../component/courses/loading/Playlist";
import ContentLoading from "../../component/courses/loading/Content";
import InteractionLoading from "../../component/courses/loading/Interaction";
import { auth, firebaseAnalytics } from "../../firebase";




const Curriculum = () => {
    const [courseData, setCourseData] = useState([]);
    const [courseDataLoading, setCourseDataLoading] = useState(true);
    const [curriculumData, setCurriculumData] = useState([]);
    const [curriculumDataLoading, setCurriculumDataLoading] = useState(true);
    const [moduleData, setModuleData] = useState([]);
    const [moduleDataLoading, setModuleDataLoading] = useState(true);
    const [doubtData, setDoubtData] = useState([]);
    const [interactionDataLoading, setInteractionDataLoading] = useState(true);
    const [noteData, setNoteData] = useState([]);
    const [progressData, setProgressData] = useState([]);
    const [progressDataLoading, setProgressDataLoading] = useState(true);
    const [moduleProgressData, setModuleProgressData] = useState([]);
    const [moduleProgressDataLoading, setModuleProgressDataLoading] = useState(true);
    const { courseSlug } = useParams();
    const history = useHistory();
    const search = useLocation().search;
    var moduleNo = new URLSearchParams(search).get('moduleNo');

    useEffect(() => {
        if (courseSlug === undefined || courseSlug === "" || courseSlug === null) {
            history.push("/courses");
        } else {
            setModuleDataLoading(true);
            authCheck();
        }
    }, [moduleNo]);

    const slugify = (string) => {
        return string
            .toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w\-]+/g, "")
            .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
    }

    const authCheck = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            if (moduleNo === undefined || moduleNo == 0 || moduleNo === null) {
                                moduleNo = 1;
                                history.push(`/learn/${courseSlug}?moduleNo=1`);
                            }

                            const enrolled = await purchaseCheck(idToken);
                            if (enrolled) {
                                getCourseData();
                                getCurriculumData(idToken);
                                getModuleData(idToken);
                                getDoubtData(idToken);
                                getNoteData(idToken);
                                getProgressData(idToken);
                                getModuleProgressData(idToken);
                            } else {
                                history.push(`/cart/${courseSlug}`);
                            }
                        }).catch(function (error) {
                            console.log(error)
                            history.push(`/course/${courseSlug}`);
                        });
                    } else {
                        console.log("else of userData");
                        history.push(`/course/${courseSlug}`);
                    }

                } else {
                    console.log("else of user");
                    history.push(`/course/${courseSlug}`);
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }


    const purchaseCheck = async (idToken) => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/taken/check/${courseSlug}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const checking = (await response.json());
            if (checking.apiStatus && checking.apiStatus != "SUCCESS" && checking.apiStatus != "Success" && checking.apiStatus != "success") {
                history.push('/courses')
            }
            if (checking.data === "PURCHASED") {
                return true;
            } else if (checking.data === "NOT_PURCHASED") {
                return false;
            } else {
                return false;
            }
        } catch (error) {
            console.log("Error in Purchase Check", error);
        }

    }



    const getCourseData = async () => {
        try {
            setCourseDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const selectedCourseData = (await response.json());
            if (selectedCourseData.apiStatus && selectedCourseData.apiStatus != "SUCCESS" && selectedCourseData.apiStatus != "Success" && selectedCourseData.apiStatus != "success") {
                history.push('/courses');
            }
            setCourseData(selectedCourseData.data);
            setCourseDataLoading(false);
        } catch (error) {
            console.log("Courses cannot be fetched.", error);
        }
    }


    const getCurriculumData = async (idToken) => {
        try {
            setCurriculumDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/courses/get/${courseSlug}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const selectedCurriculumData = (await response.json());
            if (selectedCurriculumData.apiStatus && selectedCurriculumData.apiStatus != "SUCCESS" && selectedCurriculumData.apiStatus != "Success" && selectedCurriculumData.apiStatus != "success") {
                history.push('/courses')
            }
            setCurriculumData(selectedCurriculumData.data.modules);
            setCurriculumDataLoading(false);
        } catch (error) {
            console.log("Curriculum cannot be fetched.", error);
        }
    }

    const getModuleData = async (idToken) => {
        try {
            setModuleDataLoading(true);
            setModuleProgressDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/courses/get/${courseSlug}?moduleNo=${moduleNo}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const selectedModuleData = (await response.json());
            if (selectedModuleData.apiStatus && selectedModuleData.apiStatus != "SUCCESS" && selectedModuleData.apiStatus != "Success" && selectedModuleData.apiStatus != "success") {
                history.push('/courses')
            }
            setModuleData(selectedModuleData.data);
            setModuleDataLoading(false);
            setModuleProgressDataLoading(false);
            firebaseAnalytics.logEvent(`${courseSlug}_${slugify(selectedModuleData?.data?.title)}_visited`);
        } catch (error) {
            console.log("Module cannot be fetched.", error);
        }
    }

    const getProgressData = async (idToken) => {
        try {
            setProgressDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/courses/progress/${courseSlug}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const selectedProgressData = (await response.json());
            if (selectedProgressData.apiStatus && selectedProgressData.apiStatus != "SUCCESS" && selectedProgressData.apiStatus != "Success" && selectedProgressData.apiStatus != "success") {
                history.push('/courses')
            }
            setProgressData(selectedProgressData.data);
            setProgressDataLoading(false);
        } catch (error) {
            console.log("Progresss cannot be fetched.", error);
        }
    }

    const getModuleProgressData = async (idToken) => {
        try {
            setModuleProgressDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/courses/progress/${courseSlug}?moduleNo=${moduleNo}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const selectedProgressData = (await response.json());
            if (selectedProgressData.apiStatus && selectedProgressData.apiStatus != "SUCCESS" && selectedProgressData.apiStatus != "Success" && selectedProgressData.apiStatus != "success") {
                history.push('/courses')
            }
            setModuleProgressData(selectedProgressData.data);
            setModuleProgressDataLoading(false);
        } catch (error) {
            console.log("Progresss cannot be fetched.", error);
        }
    }


    const getDoubtData = async (idToken) => {
        try {
            setInteractionDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/doubts/get/${courseSlug}?moduleNo=${moduleNo}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const selectedDoubtData = (await response.json());
            if (selectedDoubtData.apiStatus && selectedDoubtData.apiStatus != "SUCCESS" && selectedDoubtData.apiStatus != "Success" && selectedDoubtData.apiStatus != "success") {
                history.push('/courses')
            }
            setDoubtData(selectedDoubtData.data);
            setInteractionDataLoading(false);
        } catch (error) {
            console.log("Doubts cannot be fetched.", error);
        }
    }

    const getNoteData = async (idToken) => {
        try {
            setInteractionDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/notes/get/${courseSlug}?moduleNo=${moduleNo}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: idToken
                })
            });
            const selectedNoteData = (await response.json());
            if (selectedNoteData.apiStatus && selectedNoteData.apiStatus != "SUCCESS" && selectedNoteData.apiStatus != "Success" && selectedNoteData.apiStatus != "success") {
                history.push('/courses')
            }
            setNoteData(selectedNoteData.data);
            setInteractionDataLoading(false);
        } catch (error) {
            console.log("Notes cannot be fetched.", error);
        }
    }


    return (
        <>

            <Header />

            <div className="grid grid-cols-1 md:grid-cols-12 items-center mt-10">
                <div className="md:col-span-12 grid grid-cols-1 md:grid-cols-8 w-full px-2 md:px-20 mb-20 gap-4">
                    <div className="md:col-span-3 my-6 p-2 flex flex-col order-last md:order-none">
                        {
                            courseDataLoading ?
                                <SynopsisLoading />
                                :
                                <Synopsis title={courseData.title} desc={courseData.overview} />
                        }
                        {
                            progressDataLoading ?
                                <ProgressLoading />
                                :
                                <Progress progress={progressData} />
                        }
                        {
                            curriculumDataLoading || progressDataLoading ?
                                <PlaylistLoading />
                                :
                                <Playlist data={curriculumData} progress={progressData} />
                        }
                    </div>
                    <div className="md:col-span-5 my-6 flex flex-col justify-start items-center p-2">
                        {
                            moduleProgressDataLoading || moduleDataLoading ?
                                <ContentLoading />
                                :
                                moduleData?.type === "content" && moduleProgressData?.type === "content" && moduleData?.moduleNo == moduleNo && moduleProgressData?.moduleNo == moduleNo ?
                                    <Video data={moduleData} progress={moduleProgressData} courseSlug={courseSlug} moduleNo={moduleNo} />
                                    :
                                    moduleData?.type === "task" && moduleProgressData?.type === "task" && moduleData?.moduleNo == moduleNo && moduleProgressData?.moduleNo == moduleNo ?
                                        <Task data={moduleData} progress={moduleProgressData} courseSlug={courseSlug} moduleNo={moduleNo} />
                                        :
                                        null
                        }
                        {
                            moduleDataLoading || interactionDataLoading ?
                                <InteractionLoading />
                                :
                                moduleData.type === "content" ?
                                    <div className="w-full my-2 p-4 border-2 hidden md:block border-gray-700 mt-6 rounded-lg" style={{ height: "420px", overflowY: "scroll" }}>
                                        <Interaction doubts={doubtData} notes={noteData} courseSlug={courseSlug} moduleNo={moduleNo} />
                                    </div>
                                    : null
                        }
                    </div>

                </div>
            </div>


            <Footer />
        </>
    );
}

export default Curriculum;