import React from "react";
import styles from "./Brands.module.css";
import Marquee from "react-fast-marquee";

const BrandsMiniDiploma = () => {
  return (
    <div className={styles.brandscontainer}>
      <div className={styles.brandssubcontainer}>
        <div className={styles.brandsheading}>
        People from these brands 
        are our top Learners
        </div>
        <Marquee>
          <div className={styles.brands__tags_container}>
            <div className={styles.brands__tags}>
              <img src="./brands/google.png" alt="" />
            </div>
            <div className={styles.brands__tags}>
              <img src="./brands/amazon.png" alt="" />
            </div>
            <div className={styles.brands__tags}>
              <img src="./brands/flipkart.png" alt="" />
            </div>
            <div className={styles.brands__tags}>
              <img src="./brands/meesho.png" alt="" />
            </div>
            <div className={styles.brands__tags}>
              <img src="./brands/microsoft.png" alt="" />
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default BrandsMiniDiploma;
