import React from "react";

const FeaturedCardLoading = () => {
    return (
        <>
            <div className="p-4 md:w-1/4  sm:w-1/2">
                <div className="h-full border-2 border-gray-200 rounded-2xl overflow-hidden">
                    <div className="h-36 md:h-24 bg-gray-400 w-full object-cover object-center"></div>
                    <div className="p-6">
                        <div className="flex justify-between">
                            <div>
                                <h1 className="w-36 mb-4 h-4 animate-pulse bg-red-400"> &nbsp; </h1>
                                <h2 className="bg-gray-800 animate-pulse h-4 w-36 mb-2"> &nbsp; </h2>
                            </div>
                            <div
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%"
                                }}
                                className="h-48 w-48 bg-gray-400"
                            >
                            </div>
                        </div>
                        <p className="mt-20 leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                        <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                        <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                        <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                        <div className="flex items-center flex-wrap ">
                            <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0" href="/home">
                            </a>
                            <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeaturedCardLoading;