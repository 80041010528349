// Global Imports
import React from "react";

const Duration = (props) => {
    return (
        <>
            <div className="font-medium md:gap-2 flex flex-col my-4 md:flex-row md:items-center">
                Duration:
                <p className="font-normal">
                    {props.duration}
                </p>
            </div>


        </>

    );
}

export default Duration;