import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Grid, LinearProgress, Typography, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

const CourseCardAttempt = (props) => {

    const [loading, setLoading] = useState(true);
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        getCourseData();
    }, []);

    const getCourseData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${props?.course?.courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const data = (await response.json());
            setCourseData(data.data);
            setLoading(false);
        } catch (error) {
            console.log("Featured Courses cannot be fetched.", error);
        }
    }


    return (
        <>
            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <Grid item xs={12} sm={6} md={4} lg={3} className="flex justify-center p-2">
                            <Card className="card">
                                <CardMedia
                                    component="img"
                                    style={{ width: "280px", height: "100px", borderRadius: "10px" }}
                                    image={courseData?.previewImageUrl}
                                    alt={courseData?.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom style={{ color: "#ad2831", fontWeight: "bold", fontSize: "18px" }} component="div">
                                        <Link to={`/course/${props?.course?.courseSlug}`}>
                                            {courseData?.title}
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }} color="text.secondary">
                                        Taught by @ {courseData?.author}
                                    </Typography>
                                </CardContent>
                                <div className="w-full flex flex-col pb-1">
                                    <div className="flex flex-row items-center justify-between text-lg font-semibold">
                                        <p style={{ fontSize:"14px" }}>
                                            Progress:&nbsp;
                                            {props?.course?.totalProgress.toFixed(0)}
                                            %
                                        </p>
                                        <p>&nbsp;</p>
                                    </div>
                                    <LinearProgress
                                        variant="determinate"
                                        value={props?.course?.totalProgress}
                                    />
                                </div>

                                <div className="w-full flex flex-col pb-1">
                                    <div className="flex flex-row items-center justify-between text-lg font-semibold">
                                        <p style={{ fontSize:"14px" }}>
                                            Attempted:&nbsp;
                                            {props?.course?.totalAttempted.toFixed(0)}
                                            %
                                        </p>
                                        <p>&nbsp;</p>
                                    </div>
                                    <LinearProgress
                                        variant="determinate"
                                        value={props?.course?.totalAttempted}
                                    />
                                </div>


                                <div className="w-full flex flex-col pb-1">
                                    <div className="flex flex-row items-center justify-between text-lg font-semibold">
                                        <p style={{ fontSize:"14px" }}>
                                            Marks Scored:&nbsp;
                                            {props?.course?.totalMarksScored.toFixed(0)}
                                            %
                                        </p>
                                        <p>&nbsp;</p>
                                    </div>
                                    <LinearProgress
                                        variant="determinate"
                                        value={props?.course?.totalMarksScored}
                                    />
                                </div>

                                {/* <div className="grid grid-cols-1 md:grid-cols-6 w-full my-4">
                                    <div className="md:col-span-6  font-bold mb-4">
                                        Student Feedback
                                    </div>
                                </div> */}
                            </Card>
                        </Grid>

                    </>
            }
        </>
    );
}

export default CourseCardAttempt;