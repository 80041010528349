import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CertificateCard from "./CertificateCard";
import CourseCertificateEmpty from "./empty/CourseCertificateEmpty";
import CourseTakenEmpty from "./empty/CourseTakenEmpty";
import CourseCertificateLoading from "./loading/CourseCertificateLoading";

const CourseCertificate = (props) => {
    const [courseCertificate, setCourseCertificate] = useState([]);
    useEffect(() => {
        setCourseCertificate(props?.data);
    }, [props?.data]);

    return (
        <>
            {
                courseCertificate ?
                    <>
                        <Grid container>
                            <Typography
                                align="center"
                                style={{
                                    fontSize: "24px",
                                    color: "#ad2831",
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    paddingBottom: "5px",
                                }}
                            >
                                Certificates
                            </Typography>
                        </Grid>
                        <Grid container>
                        {
                            courseCertificate.length > 0 ?
                                courseCertificate?.map((course, i) => {
                                    return (
                                        <>  
                                            {
                                                course?.certificate !== "" ?
                                                    <CertificateCard cred={course?.certificate} courseSlug={course?.courseSlug} key={i} />
                                                :
                                                    <CourseCertificateEmpty courseSlug={course?.courseSlug} />
                                            }
                                        </>
                                    );
                                })

                                : <CourseTakenEmpty />
                        }
                        </Grid>
                    </>
                : <CourseCertificateLoading />
            }
        </>
    );
}

export default CourseCertificate;