// Global imports
import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "./slider.css";

const BannerSlider = () => {
    return (
        <>
            <Carousel
                autoPlay
                showArrows
                showThumbs={false}
                width={"100vw"}
                dynamicHeight={false}
                swipeable
                stopOnHover
                infiniteLoop
                className="carousel_div"
            >

                <div
                    style={{
                        width: "100%",
                        backgroundRepeat: "no-repeat",
                        overflow: "hidden",
                    }}
                    className="banner_70_companies"
                >

                    <img
                        className="banner_70_companies"
                        alt="website-rm-elearn"
                        src="/landingpage/website-rm-elearn-heroimage-wehavetrained500-companylogosanimation.gif"
                    />
                </div>
                <Link
                    to={{ pathname: "https://forms.gle/UEtYL6zRQ95jGNW29" }}
                    target="_blank"
                    className="banner_zeroToHero"
                >
                    <div
                        className="banner_zeroToHero"
                        style={{
                            width: "100%",
                            backgroundRepeat: "no-repeat",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            className="banner_zeroToHero"
                            alt="rm-website-elearn"
                            src="/landingpage/rm-website-elearn-banner-becomeatechrecruiter-2.jpg"
                        />
                    </div>
                </Link>
            </Carousel>
        </>
    );
}

export default BannerSlider;