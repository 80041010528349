import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Link, useHistory } from "react-router-dom";
import { auth } from "../../firebase";
import { Grid, CircularProgress, Button, LinearProgress } from "@material-ui/core";


const TaskResponses = (props) => {

    const [loading, setLoading] = useState(true);
    const [progressData, setProgressData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getProgress();
    }, [])


    const getProgress = async () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            setLoading(true);
                            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/progress/task/get/${props?.task?.progressID}`, {
                                method: "POST",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    token: idToken
                                })
                            });
                            const selectedProgressData = (await response.json());
                            if (selectedProgressData.apiStatus != "Success" && selectedProgressData.apiStatus != "SUCCESS" && selectedProgressData.apiStatus != "success") {
                                console.log(selectedProgressData);
                                history.push("/home");
                            }
                            setProgressData(selectedProgressData.data);
                            setLoading(false);

                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("User Progress cannot be fetched.", error);
        }
    }

    return (
        <>
            {
                loading ?
                    <div className="w-screen h-screen grid place-items-center">
                        <CircularProgress />
                    </div>
                    :
                    progressData?.completed ?
                        <Grid container className="flex items-center p-1 border-2 bg-gray-100 hover:bg-gray-200 font-medium text-center">
                            <Grid item xs={12} sm={6} md={2} className="py-1">
                                <CourseName slug={progressData?.courseSlug} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2} className="py-1">
                                <ModuleTitle slug={progressData?.courseSlug} module={progressData?.moduleNo} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="py-1">
                                <p style={{ fontSize: "14px" }}>
                                    Attempted:&nbsp;
                                    {progressData?.attempted.toFixed(0)}
                                    %
                                </p>
                                <LinearProgress
                                    variant="determinate"
                                    value={progressData?.attempted}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} className="py-1">
                                {format(new Date(progressData?.updatedAt), 'dd/MM/yyyy hh:mm:ss')}
                            </Grid>
                            <Grid item xs={6} sm={4} md={1} className="py-1">
                                <Button variant="contained" color="primary" className="button-default">
                                    <Link to={`/learn/${progressData?.courseSlug}?moduleNo=${progressData?.moduleNo}`} target="_blank">View</Link>
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={4} md={1} className="py-1">
                                Marks: {progressData?.totalScores < 0 ? "TBD*" : progressData?.totalScores}
                            </Grid>
                        </Grid>
                        :
                        null
            }
        </>
    );
}

const CourseName = (props) => {
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        getCourseData();
    }, []);

    const getCourseData = async () => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${props?.slug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const data = (await response.json());
            setCourseData(data.data);
        } catch (error) {
            console.log("Featured Courses cannot be fetched.", error);
        }
    }

    return (
        <>
            {courseData?.title}
        </>
    );
}

const ModuleTitle = (props) => {
    const [moduleData, setModuleData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getModuleData();
    }, []);

    const getModuleData = async () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/courses/get/${props?.slug}?moduleNo=${props?.module}`, {
                                method: "POST",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    token: idToken
                                })
                            });
                            const data = (await response.json());
                            setModuleData(data.data);
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("User Progress cannot be fetched.", error);
        }
    }


    return (
        <>
            {moduleData?.title}
        </>
    );
}


export default TaskResponses;