import React from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// Other Components 
import Header from "../../component/global/Header";
import CourseDetails from "../../component/preview/CourseDetails";
import Footer from "../../component/global/Footer";
import { auth } from "../../firebase";


const Overview = () => {
    const { courseSlug } = useParams();
    const history = useHistory();

    useEffect(() => {
        courseViewed();
    }, [courseSlug]);


    const courseViewed = () => {
        auth.onAuthStateChanged(async function (user) {
            if (user) {
                // User is signed in.
                var userData = await auth.currentUser;

                if (userData != null) {
                    await auth.currentUser.getIdToken().then(async function (idToken) {
                        const res = await fetch(`https://elearning-monks.azurewebsites.net/users/views/${courseSlug}`, {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                token: idToken
                            })
                        });
                        const data = await res.json();
                        if(data.apiStatus && data.apiStatus != "SUCCESS" && data.apiStatus != "Success" && data.apiStatus != "success"){
                            history.push('/courses');
                        }

                    }).catch(function (error) {
                        console.log(error)
                    });
                } else {
                    try {
                        const res = await fetch(`https://elearning-monks.azurewebsites.net/users/views/${courseSlug}`, {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            }
                        });

                        const data = await res.json();
                        if(data.apiStatus && data.apiStatus != "SUCCESS" && data.apiStatus != "Success" && data.apiStatus != "success"){
                            history.push('/courses');
                        }

                    } catch (error) {
                        console.log(error)
                    }
                }

            } else {
                try {
                    const res = await fetch(`https://elearning-monks.azurewebsites.net/users/views/${courseSlug}`, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        }
                    });

                    const data = await res.json();
                    if(data.apiStatus && data.apiStatus != "SUCCESS" && data.apiStatus != "Success" && data.apiStatus != "success"){
                        history.push('/courses');
                    }

                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    return (
        <>
            <Header />
            <CourseDetails />
            <Footer />
        </>
    );
}

export default Overview;