// Global Imports 
import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player";
import PublitioAPI from 'publitio_js_sdk'


// Other File Imports
import { auth } from "../../firebase";

// Design Imports 
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Video = (props) => {
    const videoData = props.data;
    const [videoUrl, setVideoUrl] = useState({});
    const history = useHistory();

    // useEffect(() => {
    //     const publitio = new PublitioAPI('7qe1jVflLDRyu1A2fMal', 'ze9XkGtyjby5m3hmqXpAwcA5rPe1SphV')
    //     publitio.call(`/files/show/${props?.data?.stream}`, 'GET')
    //         .then((data) => { console.log(data); setVideoUrl(data?.url_stream) })
    //         .catch((error) => { console.log(error) })
    // }, [])

    const sendVideoStart = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/progress/video/start/${props.courseSlug}?moduleNo=${props.moduleNo}&type=content`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken
                                    })
                                });
                                const selectedVideoData = await response.json();
                                if (selectedVideoData.apiStatus && selectedVideoData.apiStatus != "SUCCESS" && selectedVideoData.apiStatus != "Success" && selectedVideoData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }

    const sendVideoCompleted = () => {
        try {
            auth.onAuthStateChanged(async function (user) {
                if (user) {
                    // User is signed in.
                    var userData = await auth.currentUser;

                    if (userData != null) {
                        await auth.currentUser.getIdToken().then(async function (idToken) {
                            try {
                                const response = await fetch(`https://elearning-monks.azurewebsites.net/users/progress/video/completed/${props.courseSlug}?moduleNo=${props.moduleNo}`, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        token: idToken,
                                        videoProgress: 100,
                                    })
                                });
                                const selectedVideoData = await response.json();
                                if (selectedVideoData.apiStatus && selectedVideoData.apiStatus != "SUCCESS" && selectedVideoData.apiStatus != "Success" && selectedVideoData.apiStatus != "success") {
                                    history.push('/courses')
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }).catch(function (error) {
                            console.log(error)
                        });
                    }
                }
            });
        } catch (error) {
            console.log("Error in Authentication Check", error);
        }
    }


    return (
        <>
            {console.log(videoData)}
            <div className="w-full flex flex-col justify-center items-center">
                {videoData && videoData?.title ? (
                    <ReactPlayer
                        controls
                        className="vids"
                        url={videoData?.videoUrl}
                        onReady={console.log("ready")}
                        onStart={sendVideoStart}
                        onEnded={sendVideoCompleted}
                    />
                ) : (
                    <CircularProgress />
                )}
                {/* <h4>
                    {videoData?.title} {videoData?.description}
                </h4> */}
            </div>
        </>
    );
}

export default Video;