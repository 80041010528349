// Global Imports 
import React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";

// Other Components

// Desgin Imports 
import { Button } from "@material-ui/core";
import { Add, Visibility } from "@material-ui/icons";


const scrollToRef = (ref) => window.scrollTo(0, 0);


const PreviewCard = (props) => {
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef);


    return (
        <>
            <div className="my-4 p-2 grid grid-cols-5 w-full gap-2 mb-12 pb-6 border-b-2 border-gray-400">
                <div className="col-span-5 flex flex-col md:flex-row md:items-center gap-2 justify-between">
                    <div className="text-xl font-bold" style={{ color: "#ad2831" }}>
                        {props.preview.title}
                    </div>
                </div>
                <div className="col-span-5 text-md ">{props.preview.previewTxt}</div>
                <div className="col-span-5 gap-4 flex flex-row items-center">
                    <div className="font-medium md:gap-2 flex flex-col md:flex-row md:items-center">
                        Duration: <p className="font-normal">{props.preview.duration}</p>
                    </div>
                </div>
                <div className="col-span-5 flex flex-col md:flex-row md:items-center gap-2 md:gap-0 justify-between">
                    <div className="gap-4 flex flex-row items-center text-sm justify-between">
                        <div style={{ marginRight: "45px" }} className="font-medium text-blue-700 gap-2 flex flex-row items-center cursor-default">
                            <Add /> Taken: <p className="font-normal">{props.preview.takenBy}</p>
                        </div>
                        <div className="font-medium text-blue-700 gap-2 flex flex-row items-center cursor-default">
                            <Visibility /> Viewed: <p className="font-normal">{props.preview.viewedBy}</p>
                        </div>
                    </div>
                    <Link style={{ width: "fit-content" }} onClick={executeScroll} to={props.preview.courseSlug === "agency-to-product-recruiter"? "mini-diploma" :`/course/${props.preview.courseSlug}`}>
                        <Button ref={myRef} variant="contained" className="EM-btn">
                            View
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
}

// to={props.preview.courseSlug === "agency-to-product-recruiter"? "mini-diploma" :`/course/${props.featured.courseSlug}`}

export default PreviewCard;