import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Phone = (props) => {
    const [phone, setPhone] = useState("");
    const history = useHistory();
    useEffect(() => {
        console.log(props);
        if (props?.data?.courseTaken?.length >= 1) {
            findNumber(props?.data?.uid);
        }
    }, []);

    const findNumber = async (uid) => {
        if (localStorage.getItem('admin')) {
            const adminToken = `JWT ${JSON.parse(localStorage.getItem('admin'))}`;

            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/admin/users/payment/${uid}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Authorization": adminToken
                    }
                });

                const paymentDetails = await response.json();
                setPhone(paymentDetails?.data?.payer_phone);
            } catch (error) {
                console.log("Some error occured while submitting score", error);
            }
        } else {
            history.push("/home");
        }
    }

    return (
        <>
            {
                props?.data?.courseTaken?.length < 1 ?
                    <span className="text-gray-400">
                        -- Not Received Yet --
                    </span>
                    :
                    <span className="text-gray-900 font-semibold">
                        {phone}
                    </span>
            }
        </>
    );
}

export default Phone;