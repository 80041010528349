// Global Imports
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

// Design Imports
import { Facebook, LinkedIn, Twitter, YouTube } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core";

const scrollToRef = (ref) => window.scrollTo(0, 0);
const Footer = () => {
    // Hooks
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [open, setOpen] = useState(false);
    const topRef = useRef(null);

    const executeScroll = () => scrollToRef(topRef);

    // Email Input Handling
    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        if (email === "") return true;
        else {
            setIsValid(re.test(email));
            return re.test(email);
        }
    }
    const subscriptionInput = (e) => {
        validateEmail(e.target.value);
        setEmail(e.target.value);
    }

    // Subscribe Button pressed
    const handleClick = async () => {
        const isValid = validateEmail(email);
        if (isValid) {
            // API Request to Post to Subscriptions
            try {
                const response = await fetch(`https://elearning-monks.azurewebsites.net/subscribe/`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        email: email
                    })
                });
                const emailDataStatus = await response.json();
                handleOpen(true);

            } catch (error) {
                console.log(error);
                setIsValid(false);
            }

        } else {
            setIsValid(false);
        }
    };

    // Modal Open and Close on Successful Subscription
    const handleOpen = () => {
        setOpen(true);
        setTimeout(() => {
            handleClose();
        }, 3000);
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            {/* Footer Components */}
            <Grid container className="shadow-inner pt-10 mb-10">
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        color: "#fff",
                        p: 4
                    }} className="bg-primary">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Thanks for joining the NewsLetter
                        </Typography>
                    </Box>
                </Modal>
                {/* About the company */}
                <Grid item xs={12} sm={8} md={5} >
                    <Grid container className="flex items-center h-full">
                        <Grid item xs={4} className="flex items-end">
                            <img
                                src="/images/logo-recruitingmonk-vertical-color-transbg.png"
                                alt="Company Logo"
                                className="object-contain h-40 md:h-40"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <div className="">
                                <p className="text-xl font-bold">Recruiting Monk</p>
                                <p className="text-lg font-semibold text-gray-500">
                                    RecruitingMonk is an online platform designed to deliver recruitment
                                    gyan in a systematic and affordable fashion. The stuff we teach isn't
                                    found in books.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Sitemap */}
                <Grid item xs={12} sm={4} md={2} className="flex w-full items-center text-center justify-center py-4">
                    <Grid container>
                        <p className="text-xl font-semibold w-full justify-center">Site Map</p>
                        <Grid item sm={12} xs={6}>
                            <Link to="/home" onClick={executeScroll}>
                                <p className="text-lg text-gray-500">
                                    eLearn
                                </p>
                            </Link>
                            <Link to="/about" onClick={executeScroll}>
                                <p className="text-lg text-gray-500">
                                    About Us
                                </p>
                            </Link>
                            <Link to="/contact" onClick={executeScroll}>
                                <p className="text-lg text-gray-500">
                                    Contact Us
                                </p>
                            </Link>
                            <Link to="/privacy" onClick={executeScroll}>
                                <p className="text-lg text-gray-500">
                                    Privacy Policy
                                </p>
                            </Link>

                        </Grid>

                        <Grid item sm={12} xs={6}>
                            <Link to="/refund" onClick={executeScroll}>
                                <p className="text-lg text-gray-500">
                                    Refund Policy
                                </p>
                            </Link>
                            <Link to="/terms" onClick={executeScroll}>
                                <p className="text-lg text-gray-500">
                                    Terms of Use
                                </p>
                            </Link>
                            <Link to="/courses" onClick={executeScroll}>
                                <p className="text-lg text-gray-500">
                                    Courses
                                </p>
                            </Link>
                            <Link
                                to={{ pathname: "https://forms.gle/TD7jjAqNEb8grTan7" }}
                                target="_blank"
                            >
                                <p className="text-lg text-gray-500">Support</p>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Subscription */}
                <Grid item xs={12} sm={12} md={5} className="flex items-center justify-center">
                    <div>
                        <p className="text-xl font-semibold">Become a Monk Insider</p>
                        <p className="text-lg text-gray-500 py-2">
                            Subscribe to our newsletter to join the Monk's inner circle
                        </p>
                        <div className="flex">
                            <input
                                placeholder="Enter Your Email"
                                onChange={subscriptionInput}
                                autoComplete="off"
                                value={email}
                                name="email"
                                type="email"
                                className="md:flex-grow w-full p-2 text-xl bg-white border-2 md:border-r-0 border-primary"
                                style={{
                                    borderTopRightRadius: "6px",
                                    borderBottomRightRadius: "6px",
                                    borderTopLeftRadius: "6px",
                                    borderBottomLeftRadius: "6px",
                                }}
                            />
                            <button
                                style={{
                                    borderTopRightRadius: "6px",
                                    borderBottomRightRadius: "6px",
                                    borderTopLeftRadius: "6px",
                                    borderBottomLeftRadius: "6px",
                                }}
                                onClick={handleClick}
                                className="p-2 border-2 border-primary bg-primary text-white font-semibold text-lg"
                            >
                                Subscribe
                            </button>
                        </div>
                        {!isValid ? (
                            <p style={{ color: "red" }} className="font-semibold">Please Enter Valid Email</p>
                        ) : (
                            false
                        )}


                        {/* CopyRight & Follow Us */}
                        <Grid container className="pt-3">
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-row items-center w-full text-2xl gap-4">
                                    <p className="text-xl font-semibold">Follow Us</p>
                                    {[
                                        <Link
                                            to={{ pathname: "https://www.linkedin.com/company/recruitingmonk" }}
                                            target="_blank"
                                        >
                                            <LinkedIn
                                                onClick={() =>
                                                    window.open(
                                                        "https://www.linkedin.com/company/recruitingmonk",
                                                        "_blank"
                                                    )
                                                }
                                                size={40}
                                            />
                                        </Link>,
                                        <Link
                                            to={{ pathname: "https://www.facebook.com/recruitingmonk" }}
                                            target="_blank"
                                        >
                                            <Facebook
                                                onClick={() =>
                                                    window.open(
                                                        "https://www.facebook.com/recruitingmonk",
                                                        "_blank"
                                                    )
                                                }
                                                size={40}
                                            />
                                        </Link>,
                                        <Link
                                            to={{ pathname: "https://twitter.com/recruitingmonk" }}
                                            target="_blank"
                                        >
                                            <Twitter
                                                onClick={() =>
                                                    window.open("https://twitter.com/recruitingmonk", "_blank")
                                                }
                                                size={40}
                                            />
                                        </Link>,
                                        <Link
                                            to={{ pathname: "https://www.youtube.com/channel/UCh12zgrebTnSYRerv9rxMVQ" }}
                                            target="_blank"
                                        >
                                            <YouTube
                                                onClick={() =>
                                                    window.open(
                                                        "https://www.youtube.com/channel/UCh12zgrebTnSYRerv9rxMVQ",
                                                        "_blank"
                                                    )
                                                }
                                                size={40}
                                            />
                                        </Link>,
                                    ].map((icon, i) => (
                                        <div className="w-10 h-10 text-center rounded-full bg-primary text-white" key={i}>
                                            {icon}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Grid>
                    </div>


                </Grid>
            </Grid>


        </>
    );
}

export default Footer;