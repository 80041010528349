// Global Imports
import React from "react";

const Purview = (props) => {
    return (
        <>
            <div className="col-span-5 text-md ">{props.purview}</div>
        </>
    );
}

export default Purview;