import React from "react";

const Loading = () => {
    return (
        <>
            <div style={{ width: "85vw" }}>

                {/* title */}
                <div className="w-full self-start flex flex-col md:flex-row md:items-center gap-2 mb-6 justify-between">
                    <div className="h-8 md:w-1/3 w-1/2 animate-pulse bg-red-300">
                    </div>
                </div>

                {/* purview */}
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-8"></div>

                {/* Desciption */}
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-8"></div>

                {/* Ideal */}
                <div className="w-12 h-5 animate-pulse bg-gray-700 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/2 w-full h-5 animate-pulse bg-gray-300 mb-8"></div>

                {/* Duration */}
                <div className="md:w-1/3 w-1/2 h-5 animate-pulse bg-gray-300 mb-8"></div>

                {/* Mode */}
                <div className="w-12 h-5 animate-pulse bg-gray-700 mb-2"></div>
                <div className="md:w-1/3 w-1/2 h-5 animate-pulse bg-gray-300 mb-2"></div>
                {/* Requirement */}
                <div className="w-12 h-5 animate-pulse bg-gray-700 mb-2"></div>
                <div className="md:w-1/3 w-1/2 h-5 animate-pulse bg-gray-300 mb-2"></div>
                {/* Mentorship */}
                <div className="w-12 h-5 animate-pulse bg-gray-700 mb-2"></div>
                <div className="md:w-1/3 w-1/2 h-5 animate-pulse bg-gray-300 mb-8"></div>

                {/* Upon Completion */}
                <div className="w-12 h-5 animate-pulse bg-gray-700 mb-2"></div>
                <div className="md:w-1/3 w-1/2 h-5 animate-pulse bg-gray-300 mb-2"></div>
                <div className="md:w-1/3 w-1/2 h-5 animate-pulse bg-gray-300 mb-8"></div>

                {/* Taken */}
                <div className="md:w-1/2 w-full flex">
                    <div className="w-1/4 h-6 animate-pulse bg-blue-500 justify-start mb-8"></div>
                    <div className="w-1/4 h-6"></div>
                    <div className="w-1/4 h-6 animate-pulse bg-blue-500 justify-end mb-8"></div>
                </div>

                {/* Price */}
                <div className="w-24 h-5 animate-pulse bg-gray-700 mb-2"></div>
                <div className="w-24 h-5 animate-pulse bg-gray-300 mb-2"></div>


                <div className="block h-6 w-24 animate-pulse bg-red-400">
                </div>

            </div>
        </>
    );
}

export default Loading;