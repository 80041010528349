import React, { useState } from 'react';
import { storage } from '../../../firebase';

const ImageUpload = (props) => {
    const [selected, setSelected] = useState("/logoimage/add-image.png");
    const [progress, setProgress] = useState(0);

    const selectFileHandler = (e) => {
        const { files } = e.target;
        const file = files[0];
        if (file) {

            const uploadTask = storage.ref('courses_preview').child(file.name).put(file);
            uploadTask.on(
                'state_changed',
                snapshot => {
                    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    console.log('Upload is ' + progress + '% done');
                }, error => {
                    console.log(error);
                }, () => {
                    storage.ref("courses_preview").child(file.name).getDownloadURL().then(url => {
                        props.setData({ ...props.data, [props.name]: url });
                        setSelected(url)
                    }).catch(err => {
                        console.log(err)
                    })
                });


        }
    };

    const acceptableFileTypes = {
        png: "image/png",
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        gif: "image/gif",
        svg: "image/svg+xml",
        imgs: 'image/*',
    };

    return (
        <>
            <div className='p-3'>
                <div className='font-bold text-slate-700 mb-2'>
                    {props.label}
                </div>
                <label
                    className="block mb-2 text-sm h-48 bg-cover w-48 p-2 rounded-lg shadow-lg border-[1px] font-medium text-gray-900 dark:text-gray-300"
                    htmlFor={props.id}
                    style={{ backgroundImage: `url(${props.data[props.name] || selected})`, backgroundSize:"cover", backgroundRepeat:"no-repeat" }}
                >

                </label>
                <input
                    className="hidden"
                    aria-describedby="user_avatar_help"
                    id={props.id}
                    type="file"
                    accept={acceptableFileTypes}
                    name={props.name}
                    onChange={selectFileHandler}
                />

                {
                    (progress > 0 && progress < 100) && (
                        <div className='text-gray-600 mt-2'>
                            Upload is {progress} % done. 
                        </div>
                    )
                }

                {
                    progress === 100 && (
                        <div className='text-green-800 mt-2'>
                            Successfully Uploaded
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default ImageUpload;