import React from "react";
import styles from "./TrainerInfo.module.css";

const TrainerInfo = () => {
  return (
    <div className={styles.tarinerinfo_container}>
      <div className={styles.trainerinfo_subcontainer}>
        <div
          className={`${styles.trainer_message_box} ${
            styles.highlight_animation
          }`}
        >
          <div className={styles.message}>
            Meet your <br /> trainer.
          </div>
          <div className={styles.message_arrow}>
            <img src="/images/arrowsvg.svg" alt="" />
          </div>
        </div>

        <div className={styles.trainerinfo_subheading}>
          10 years of training and 4 years of content writing creating an impact of -
        </div>

        <div className={styles.tarinerinfo_heading_container}>
          <div className={styles.trainerinfo_heading}>
            <span>900+ </span>Posts on LinkedIn
          </div>
          <div className={styles.trainerinfo_heading}>
            <span>10M+</span> Impressions
          </div>
          <div className={styles.trainerinfo_heading}>
            <span>10,000+</span> Recruiters Trained
          </div>
        </div>

        <div className={styles.trainer_about_info}>
          <div className={styles.tariner_image}>
            <img
              src="/images/ashfaq_image.png"
              className={styles.ashfaq_image}
              alt=""
            />
          </div>

          <div className={styles.tariner_personal_info}>
            <div className={styles.trainer_name}>Ashfaq Ahmed</div>
            <div className={styles.trainer_designation}>
              Founder & Head Monk @ Recruiting Monk
            </div>
          </div>
        </div>

        <div className={styles.trainer_about}>
          Ashfaq is an engineer by chance but a recruiter by choice. With over 12
          years of recruitment experience, including 10 years dedicated to
          training and nurturing recruiters, he has trained more than{" "}
          <span>10,000 tech recruiters.</span>
        </div>

        <div className={styles.trainer_message_container}>
          <div className={styles.trainer_message_semi_container}>
            <p style={{marginBottom:"0"}}>Like what you see ?</p>
            <button
              className={styles.join_button_option}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/ashfaqahmedhr/",
                  "_blank"
                )
              }
              style={{
                border: "1.5px solid #191919",
              }}
            >
              <div>Check out his LinkedIn Profile</div>
              <div>
                <svg
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  width={25}
                >
                  <path
                    fill="#0A66C2"
                    d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 0 1-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 0 0 2 2.866v10.268a.88.88 0 0 0 .885.866h10.226a.882.882 0 0 0 .889-.866V2.865a.88.88 0 0 0-.889-.864z"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerInfo;
