// Global Imports
import React from "react";

// Design Imports
import "./Array.css";

const Perks = (props) => {
    return (
        <>
            <div className="font-medium md:gap-2 flex-col md:flex-row md:items-center">
                Upon Completion:
            </div>
            <ul className="array">
                {
                    props.perks?.map((line, i) => {
                        return (
                            <li key={i}>{line}</li>
                        );
                    })
                }
            </ul>

        </>

    );
}

export default Perks;