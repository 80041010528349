// Global file imports 
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Other File Imports
import Footer from "../../component/global/Footer";
import Header from "../../component/global/Header";
import CourseCertificate from "../../component/learning/CourseCertificate";
import CourseTaken from "../../component/learning/CourseTaken";
import CourseViews from "../../component/learning/CourseViews";
import Doubts from "../../component/learning/Doubts";
import Tasks from "../../component/learning/Tasks";
import UserInfo from "../../component/learning/UserInfo";
import { auth } from "../../firebase";



const Learning = () => {

    const [userData, setUserData] = useState([]);
    const [userTaskData, setUserTaskData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        auth.onAuthStateChanged(async function (user) {
            if (user) {
                // User is signed in.
                var userData = await auth.currentUser;

                if (userData != null) {
                    await auth.currentUser.getIdToken().then(async function (idToken) {
                        try {
                            const response = await fetch(`https://elearning-monks.azurewebsites.net/users/profile`, {
                                method: "POST",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    token: idToken
                                })
                            });

                            const userdetails = await response.json();
                            if (userdetails.apiStatus && userdetails.apiStatus != "SUCCESS" && userdetails.apiStatus != "Success" && userdetails.apiStatus != "success") {
                                history.push("/home");
                                console.log(userdetails);
                            } else {
                                setUserData(userdetails?.data);
                                setUserTaskData(userdetails?.data?.coursePlaylist?.filter(x => x.type == "task"));
                            }
                        } catch (error) {
                            console.log(error);
                            history.push("/home");
                        }
                    }).catch(function (error) {
                        console.log(error)
                        history.push("/home");
                    });
                } else {
                    console.log("else of userData");
                    history.push("/home");
                }
            } else {
                console.log("else of user");
                history.push("/home");
            }
        });
    }, []);


    return (
        <>
            <Header />
            <div style={{ backgroundColor: "whitesmoke" }}>
                <UserInfo name={userData?.name} email={userData?.email} profilePhoto={userData?.profilePhoto} />
                <CourseViews data={userData?.courseViews} />
                <CourseTaken data={userData?.courseTaken} />
                <CourseCertificate data={userData?.courseTaken} />
                <Doubts data={userData?.userDoubts} />
                <Tasks data={userTaskData} />
            </div>
            <Footer />
        </>
    );
}

export default Learning;