import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    // apiKey: "AIzaSyCLsPyzEa0K3o7eFNn5q6cg1oFV3sWhFVo",
    // authDomain: "elearning-261cd.firebaseapp.com",
    // databaseURL: "https://elearning-261cd-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "elearning-261cd",
    // storageBucket: "elearning-261cd.appspot.com",
    // messagingSenderId: "874339655178",
    // appId: "1:874339655178:web:8852bda9cafdde5919c9f3",
    // measurementId: "G-SQ20W8G1V6"
    apiKey: "AIzaSyBV-JHY2x5cvy8nz56qiU6rRaMUgvPi6K0",
    authDomain: "elearning-monks.firebaseapp.com",
    databaseURL: "https://elearning-monks-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "elearning-monks",
    storageBucket: "elearning-monks.appspot.com",
    messagingSenderId: "547582971520",
    appId: "1:547582971520:web:1d62882fafe524fb496065",
    measurementId: "G-D27RFTXKF4"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
// auth.setPersistence(firebase.auth.Auth.Persistence.NONE)
const firebaseAnalytics = firebase.analytics();

const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

export { auth, provider, storage, firebaseAnalytics };
export default firebaseApp;