// Global Imports 
import React from "react";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Other Components 
import { auth } from "../../firebase";

// design imports
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import Stepper from "@material-ui/core/Stepper";
import { Clear } from "@material-ui/icons";
import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    TextField,
} from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import Swal from "sweetalert2";



const loadRazorPay = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        document.body.appendChild(script);
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => reject(false);
    });
};


const Payment = (props) => {
    const history = useHistory();
    const [courseData, setCourseData] = useState([]);
    const [courseDataLoading, setCourseDataLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [couponCode, setCouponCode] = useState("");
    const [coupon, setCoupon] = useState(0);
    const [price, setPrice] = useState(0);
    const [couponValue, setCouponValue] = useState(false)
    const [priceValue, setpriceValue] = useState(false)
    const [totalpriceValue, settotalpriceValue] = useState(true)
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [isError, setIsError] = useState(false);
    const [linkedinUrl, setLinkedinUrl] = useState("");
    const { courseSlug } = useParams();



    useEffect(() => {
        setName(props.name);
        setEmail(props.email)
        if (courseSlug === undefined || courseSlug === "" || courseSlug === null) {
            history.push("/courses");
        } else {
            getCourseData();
        }
    }, [courseSlug]);

    const getCourseData = async () => {
        try {
            setCourseDataLoading(true);
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const selectedCourseData = (await response.json());
            if (selectedCourseData.apiStatus && selectedCourseData.apiStatus != "SUCCESS" && selectedCourseData.apiStatus != "Success" && selectedCourseData.apiStatus != "success") {
                history.push('/courses')
            }
            setCourseData(selectedCourseData.data);
            setCourseDataLoading(false);

        } catch (error) {
            console.log("Courses cannot be fetched.", error);
        }
    }

    function getSteps() {

        return [
            `Selected Course: ${courseData && courseData.title}`,
            `Billing & Amount ₹${Number(totalpriceValue ? courseData && courseData.price : priceValue ? courseData && price : courseData && courseData.price)
            }`,
            `Required Registration Details`,
        ];
    }

    const steps = getSteps();

    const applyCoupon = () => {
        const couponFound = courseData.coupons.find(x => x.code == couponCode);
        if (couponFound === undefined || couponFound === null) {
            setCoupon(0);
            setCouponValue(false);
            setPrice(courseData.price);
            setpriceValue(false);
            settotalpriceValue(true);
        } else {
            var d1 = new Date();
            var d2 = new Date(couponFound.validTill);

            if (couponFound.type == "percent" && d1.getTime() < d2.getTime()) {
                setCoupon(couponFound.value * 0.01 * courseData.price)
                setPrice(courseData.price - couponFound.value * 0.01 * courseData.price)
                setCouponValue(true)
                setpriceValue(true)
                settotalpriceValue(false)
            } else if (couponFound.type == "value" && d1.getTime() < d2.getTime()) {
                setCoupon(courseData.price - couponFound.value)
                setPrice(couponFound.value)
                setCouponValue(true)
                setpriceValue(true)
                settotalpriceValue(false)
            } else {
                console.log(d2.getTime() > d1.getTime())
            }
        }
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-row items-center p-2 ring-1 ring-gray-500 justify-between">
                            <div className="flex flex-col">
                                <p className="text-lg font-semibold"><CalendarTodayOutlined /> {courseData.title}</p>
                            </div>
                            <div>
                                <Clear />
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="flex flex-col ">
                        <div className="flex flex-col">
                            <div className="text-lg font-medium flex flex-row justify-between">
                                <p>Cost</p>
                                <p>₹{courseData.price}</p>
                            </div>
                            {courseData?.coupons && (
                                <div className="text-lg font-medium flex flex-row justify-between">
                                    <p style={{ display: "flex" }}>

                                        <TextField
                                            label="Coupon Code"
                                            fullWidth
                                            style={{ marginLeft: "1rem" }}
                                            onChange={(e) => setCouponCode(e.target.value)}
                                        />
                                        <Button onClick={applyCoupon} className=" hover:bg-gray-400 " style={{ color: "#ad2831" }}>Apply</Button>
                                    </p>
                                    {couponValue && <p>{coupon}</p>}
                                </div>
                            )}
                            {/* {courseData?.earlyBird && (
                                <div className="text-lg font-medium flex flex-row justify-between">
                                    <p>
                                        <Checkbox /> Early Bird - Before 15 Sep 2021
                                    </p>
                                    <p>-{courseData?.earlyBird?.value}</p>
                                </div>
                            )}
                            {courseData?.discount && (
                                <div className="text-lg font-medium flex flex-row justify-between">
                                    <p>
                                        <Checkbox /> Introducing others discount{" "}
                                    </p>
                                    <p>{coupon}</p>
                                </div>
                            )} */}
                            <Divider />
                            <div className="text-lg font-medium flex flex-row justify-between my-4">
                                <p>Total: </p>
                                {priceValue && <p> {price}</p>} {totalpriceValue && <p>{courseData.price}</p>}
                            </div>
                            <Divider />
                        </div>
                        <Divider />
                    </div>
                );
            case 2:
                return (
                    <div className="flex flex-col">
                        <p className="text-lg font-semibold my-2">Personal Details</p>
                        <Divider />
                        <TextField
                            error={name.length === 0}
                            required
                            label="Enter your Name"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            error={email.length === 0}
                            required
                            label="Enter your Email"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            error={contact.length === 0}
                            required
                            label="Enter your valid Mobile"
                            fullWidth
                            value={contact}
                            onChange={(e) => {
                                setContact(e.target.value); if (e.target.value.length > 10) {
                                    setIsError(true)
                                }
                            }}
                        />
                        <TextField
                            label="LinkedIn Profile URL(Optional)"
                            fullWidth
                            value={linkedinUrl}
                            onChange={(e) => setLinkedinUrl(e.target.value)}
                        />
                        <Divider />
                    </div>
                );
            default:
                return "Unknown step";
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };
    const handleTestAccess = () => {
        auth.onAuthStateChanged(async function (user) {
            if (user) {
                // User is signed in.
                var userData = await auth.currentUser;

                if (userData != null) {
                    await auth.currentUser.getIdToken().then(async function (idToken) {

                        const res = await fetch(`https://elearning-monks.azurewebsites.net/users/taken/${courseSlug}`, {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                token: idToken,
                                name,
                                email,
                                coupon:couponCode,
                                contact,
                                payment_id:`pay_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`,
                                order_id:`order_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`,
                                amount: Number(totalpriceValue ? courseData && courseData.price : priceValue ? courseData && price : courseData && courseData.price)
                            })
                        });

                        const data = await res.json();
                        if (data.apiStatus && data.apiStatus != "SUCCESS" && data.apiStatus != "Success" && data.apiStatus != "success") {
                            // history.push('/courses')
                            return Swal.fire({
                                icon: "error",
                                title: "Something went wrong! Contact Support",
                            })
                        }

                    }).catch(function (error) {
                        console.log(error)
                    });
                }

            }
        });
        Swal.fire({
            icon: "success",
            title: "Payment Successful",
        });
        return history.push(
            `/course/${courseSlug}`
        );
    }



    const processRazorPay = async () => {
        if (!name || !email || !contact) {
            return Swal.fire({
                icon: "info",
                title: "Please provide required payment details",
            });
        }

        if (priceValue && price == 0) {
            handleTestAccess();
        } else {


            const res = await loadRazorPay(
                "https://checkout.razorpay.com/v1/checkout.js"
            );



            // const requestOptions = {
            //     method: "POST",
            //     redirect: "follow",
            //     mode: "no-cors",
            //     statusCode: 500,
            //     headers: {
            //         "Access-Control-Allow-Headers": "Content-Type",
            //         "Access-Control-Allow-Origin": "*",
            //         "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
            //     },
            //     body: JSON.stringify({
            //         currency: "INR",
            //         amount: 100 * (Number(totalpriceValue ? courseData && courseData.price : priceValue ? courseData && price : courseData && courseData.price)),
            //         notes: {
            //             "course": courseData.title,
            //             "slug": courseData.courseSlug,
            //             name,
            //             email,
            //             contact,
            //         }
            //     })
            // };

            const orderGenerated = await fetch(
                "https://elearning-monks.azurewebsites.net/payment/order", {
                method: "POST",
                redirect: "follow",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
                },
                body: JSON.stringify({
                    currency: "INR",
                    amount: 100 * (Number(totalpriceValue ? courseData && courseData.price : priceValue ? courseData && price : courseData && courseData.price)),
                    notes: {
                        "course": courseData.title,
                        "slug": courseData.courseSlug,
                        "overview": courseData.overview
                    }
                })
            });

            const orderData = await orderGenerated.json();
            if (orderData.apiStatus && orderData.apiStatus != "SUCCESS" && orderData.apiStatus != "Success" && orderData.apiStatus != "success") {
                return Swal.fire({
                    icon: "error",
                    title: "Something went wrong! Contact Support",
                })
            }



            if (!res) {
                return Swal.fire({
                    icon: "error",
                    title: "Are you Online?",
                });
            }



            var options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: (Number(totalpriceValue ? courseData && courseData.price : priceValue ? courseData && price : courseData && courseData.price)) * 100,
                currency: courseData.currency,
                name: "Recruiting Monk",
                description: "Buy Online Course",
                image: "https://firebasestorage.googleapis.com/v0/b/elearning-261cd.appspot.com/o/logo.png?alt=media&token=8dca0865-14f0-4566-b483-86edd6c2fc0b",
                notes: {
                    "course": courseData.title,
                    "slug": courseData.courseSlug,
                    "overview": courseData.overview
                },
                order_id: orderData?.data?.id,
                handler: async function (response) {
                    if (response.razorpay_payment_id) {
                        const payment_id = response.razorpay_payment_id;
                        const order_id = response.razorpay_order_id;

                        const verifyOrder = await fetch(
                            "https://elearning-monks.azurewebsites.net/payment/verification", {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                "x-razorpay-signature": response.razorpay_signature
                            },
                            body: JSON.stringify({
                                order_id,
                                payment_id
                            })
                        });

                        const verification = await verifyOrder.json();
                        if (verification.apiStatus && verification.apiStatus != "SUCCESS" && verification.apiStatus != "Success" && verification.apiStatus != "success") {
                            return Swal.fire({
                                icon: "error",
                                title: verification?.data,
                            })
                        }

                        history.push(`/course/${courseSlug}`);

                        auth.onAuthStateChanged(async function (user) {
                            if (user) {
                                // User is signed in.
                                var userData = await auth.currentUser;

                                if (userData != null) {
                                    await auth.currentUser.getIdToken().then(async function (idToken) {

                                        const res = await fetch(`https://elearning-monks.azurewebsites.net/users/taken/${courseSlug}`, {
                                            method: "POST",
                                            headers: {
                                                Accept: "application/json",
                                                "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify({
                                                token: idToken,
                                                name,
                                                email,
                                                contact,
                                                coupon:couponCode,
                                                payment_id,
                                                order_id,
                                                amount: Number(totalpriceValue ? courseData && courseData.price : priceValue ? courseData && price : courseData && courseData.price)
                                            })
                                        });

                                        const data = await res.json();
                                        if (data.apiStatus && data.apiStatus != "SUCCESS" && data.apiStatus != "Success" && data.apiStatus != "success") {
                                            // history.push('/courses')
                                            return Swal.fire({
                                                icon: "error",
                                                title: "Something went wrong! Contact Support",
                                            })
                                        }

                                    }).catch(function (error) {
                                        console.log(error)
                                    });
                                }

                            }
                        });
                        Swal.fire({
                            icon: "success",
                            title: "Payment Successful",
                        });
                        return history.push(
                            `/course/${courseSlug}`
                        );
                    } else
                        return Swal.fire({
                            icon: "error",
                            title: "Unable to process payment!",
                        });
                },
                prefill: {
                    name: name,
                    email: email,
                    contact: contact,
                },
                theme: {
                    color: "#B0343C",
                },
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.open();
        }
    };


    return (
        <>
            {
                courseData ?
                    <div className="grid grid-cols-1 md:grid-cols-12 px-4 md:px-20 mt-6">
                        <div className="md:col-span-9 flex flex-col items-center w-full">
                            <div className="grid grid-cols-1 md:grid-cols-6 w-full">
                                <Stepper
                                    activeStep={activeStep}
                                    orientation="vertical"
                                    className="md:col-span-6"
                                >
                                    {steps.map((label, index) => (
                                        <Step key={index}>
                                            <p className="text-xl font-semibold bg-gray-300 p-2">
                                                <span className="font-normal">{index + 1}: </span> {label}
                                            </p>
                                            <StepContent>
                                                <div className="my-4">{getStepContent(index)}</div>
                                                <div className="flex flex-row items-center justify-end gap-10">
                                                    {index !== 0 && (
                                                        <button
                                                            className="p-2 px-4 ring-2 ring-yellow-600"
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                        >
                                                            Back
                                                        </button>
                                                    )}
                                                    <button
                                                        id="payment_clickHandler"
                                                        className="p-2 px-4 ring-2 ring-yellow-600 bg-red-300"
                                                        onClick={() =>
                                                            activeStep === steps.length - 1
                                                                ? contact.length !== 0 && contact.length >= 10 &&
                                                                    name.length !== 0 &&
                                                                    email.length !== 0
                                                                    ? processRazorPay()
                                                                    : alert("Poblems in input.")
                                                                : handleNext()
                                                        }
                                                    >
                                                        {activeStep === steps.length - 1
                                                            ? "Proceed to payment"
                                                            : "Next"}
                                                    </button>
                                                </div>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>


                            </div>
                        </div>
                    </div>
                    :
                    <div className="h-full w-full grid place-items-center">
                        <CircularProgress />
                    </div>
            }
        </>
    );
}

export default Payment;