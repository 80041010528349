import React from "react";
import styles from "./SneakPeek.module.css";
import ReactPlayer from "react-player";
import Sessions from "./MiniDiplomaSessions/Sessions";

const SneakPeek = () => {
  return (
    <div className={styles.sp_container}>
      <div className={styles.sp_subcontainer}>
        <div className={styles.sp_heading}>Inside Mini Diploma: A Sneak Peek</div>
        <div className={`${styles.sp_message_box } ${styles.highlight_animation}`}>
          <div className={styles.message}>
            what you could <br /> expect from the <br /> program
          </div>
          <div className={styles.message_arrow}>
            <img src="/images/arrowsvg.svg" alt="" />
          </div>
        </div>
        {/* <div className={styles.sp_video_container}> */}
        <Sessions />
        {/* </div> */}
      </div>
    </div>
  );
};

export default SneakPeek;
