import React from "react";
import { Link } from "react-router-dom";

const CourseViewsEmpty = () => {
    return (
        <>
            <div className="flex justify-between px-4 w-full">
                <div className="text-lg text-gray-600">
                    -- No Course Views --
                </div>
                <Link to="/courses" className="text-lg font-semibold" style={{ color: "#ad2831" }}>Explore</Link>
            </div>

        </>
    );
}

export default CourseViewsEmpty;