import React from "react";

const Content = () => {
    return (
        <>
            <div className="h-96 animate-pulse bg-gray-200 mb-24" style={{ width: "36rem" }}>
            </div>
        </>
    );
}

export default Content;


