// Global imports
import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import TagManager from "react-gtm-module";

// Design imports
// import { CircularProgress } from "@material-ui/core";

// Container Imports
import Home from "./containers/appinfo/Home";
import About from "./containers/appinfo/About";
import Contact from "./containers/appinfo/Contact";
import Privacy from "./containers/appinfo/Privacy";
import Refund from "./containers/appinfo/Refund";
import Terms from "./containers/appinfo/Terms";
import AllCourses from "./containers/appinfo/AllCourses";
import Blogs from "./containers/author/Blogs";
import Admin from "./containers/admin/Admin";
import Dashboard from "./containers/admin/Dashboard";
import ListCourses from "./containers/admin/ListCourses";
import Cart from "./containers/courses/Cart";
import Curriculum from "./containers/courses/Curriculum";
import Certificate from "./containers/courses/Certificate";
import Overview from "./containers/courses/Overview";
import StudentVideos from "./containers/courses/StudentVideos";
import Learning from "./containers/user/Learning";

// Component Imports
import MsgBot from "./component/global/MsgBot";
import DoubtAnswering from "./containers/admin/DoubtAnswering";
import User from "./containers/admin/User";
import UserProfile from "./containers/admin/UserProfile";
import AddCourse from "./containers/admin/AddCourse";
import StoryTelling from "./containers/story_telling/StoryTelling";
import MiniDiploma from "./containers/mini_diploma_page/MiniDiploma";

// import Temp from './containers/courses/Temp';

const Router = () => {
  const [msg, setMsg] = useState(false);

  useEffect(() => {
    // getData();
    // ReactGa.initialize("GTM-594NZ8R");
    // ReactGa.pageview(window.location.pathname);
    TagManager.initialize({ gtmId: "GTM-594NZ8R" });
  }, []);

  return (
    <>
      <Switch>
        <Route exact path="/" component={() => <> <Home /> <MsgBot msg={msg} setMsg={setMsg} /></>} />
        <Route exact path="/home" component={() => <Home />} />
        <Route exact path="/about" component={() => <About />} />
        <Route exact path="/privacy" component={() => <Privacy />} />
        <Route exact path="/terms" component={() => <Terms />} />
        <Route exact path="/refund" component={() => <Refund />} />
        <Route exact path="/contact" component={() => <Contact />} />
        <Route exact path="/courses" component={() => <AllCourses />} />
        <Route path="/course/:courseSlug" component={() => <Overview />} />

        <Route path="/mini-diploma" component={() => <MiniDiploma />} />

        <Route path="/videos/:courseSlug" component={() => <StudentVideos />} />

        {/* Login Necessary */}
        <Route path="/cart/:courseSlug" component={() => <Cart />} />
        <Route
          path="/certificate/:courseSlug"
          component={() => <Certificate />}
        />
        <Route path="/learn/:courseSlug" component={() => <Curriculum />} />
        <Route path="/learning" component={() => <Learning />} />
        <Route path="/storytelling" component={() => <StoryTelling/>}/>

        {/* Admin Auth Necessary */}
        <Route exact path="/admin" component={() => <Admin />} />
        <Route exact path="/admin/dashboard" component={() => <Dashboard />} />

        <Route exact path="/admin/add/course" component={() => <AddCourse />} />

        <Route exact path="/admin/courses" component={() => <ListCourses />} />

        <Route exact path="/admin/users" component={() => <User />} />

        <Route
          exact
          path="/admin/user/:userID"
          component={() => <UserProfile />}
        />

        <Route
          exact
          path="/admin/doubt/:doubtID"
          component={() => <DoubtAnswering />}
        />

        {/* Author Auth Necessary */}
        <Route exact path="/blogs" component={() => <Blogs />} />

        {/* <Route
                    path="/temp/:courseSlug"
                    component={() => (
                        <Temp />
                    )}
                /> */}
      </Switch>
    </>
  );
};

export default Router;
