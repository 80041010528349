import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

const CourseCardMini = (props) => {
    const [courseData, setCourseData] = useState([]);

    useEffect(() => {
        getCourseData();
    }, []);

    const getCourseData = async () => {
        try {
            const response = await fetch(`https://elearning-monks.azurewebsites.net/courses/${props?.courseSlug}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const data = (await response.json());
            setCourseData(data.data);
        } catch (error) {
            console.log("Featured Courses cannot be fetched.", error);
        }
    }

    return (
        <>
            <Grid item xs={12} sm={6} md={4} lg={3} className="block p-3 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <Link to={`/course/${props?.courseSlug}`}>
                    <h5 className="text-lg tracking-tight text-gray-900 dark:text-white">
                        {courseData?.title}
                    </h5>
                </Link>
            </Grid>
        </>
    );
}

export default CourseCardMini;