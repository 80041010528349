import React from "react";

const TasksLoading = () => {
    return (
        <>
            <div className="p-2">
                <div className="bg-red-300 animate-pulse h-10 w-1/2 md:w-1/4 my-3"></div>
                <div className="w-full mb-4 h-10 animate-pulse bg-gray-300"></div>
            </div>
        </>
    );
}

export default TasksLoading;