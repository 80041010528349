import React,{ useEffect } from "react";
import "./appinfo.css";
import Header from "../../component/global/Header";
import Footer from "../../component/global/Footer";
import { firebaseAnalytics } from "../../firebase";

const Terms = () => {
    useEffect(()=>{
        document.title="Terms & Conditions | Elearning - Recruiting Monk";
        firebaseAnalytics.logEvent("terms_and_conditions_visited");
    });
    return (
        <>  
            <Header />
            <div className="appinfo-body">
                <div className="appinfo-main shadow-inner">
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "18pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Te
                        </span>
                        <span
                            style={{
                                fontSize: "18pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            rms o
                        </span>
                        <span
                            style={{
                                fontSize: "18pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            f Use
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "13.999999999999998pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            1. AGREEMENT TO TERMS
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            These Terms of Use constitute a legally binding agreement made between
                            you, whether personally or on behalf of an entity ("you") and ("Company,"
                            "we," "us," or "our"), concerning your access to and use of the website as
                            well as any other media form, media channel, mobile website or mobile
                            application related, linked, or otherwise connected thereto (collectively,
                            the "Site"). You agree that by accessing
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            the Site, you have read, understood, and agreed to be bound by all of
                            these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE,
                            THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
                            DISCONTINUE USE IMMEDIATELY. Supplemental terms and conditions or
                            documents that may be posted on the Site from time to time are hereby
                            expressly incorporated herein by reference. We reserve the right, in our
                            sole discretion, to make changes or
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            modifications to these Terms of Use at any time and for any reason. We
                            will alert you about any changes by updating the "Last updated" date of
                            these Terms of Use, and you waive any right to receive specific notice of
                            each such change. Please ensure that you check the applicable Terms every
                            time you use our Site so that you understand which Terms apply. You will
                            be subject to, and will be deemed to have been made aware of and to have
                            accepted, the changes in any revised Terms of Use by your continued use of
                            the Site after the date such revised Terms of Use are posted.
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            The information provided on the Site is not intended for distribution to
                            or use by any person or entity in any jurisdiction or country where such
                            distribution or use would be contrary to law or regulation or which would
                            subject us to any registration requirement within such jurisdiction or
                            country. Accordingly, those persons who
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            choose to access the Site from other locations do so on their own
                            initiative and are solely responsible for compliance with local laws, if
                            and to the extent local laws are applicable.
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "13.999999999999998pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            2. INTELLECTUAL PROPERTY RIGHTS
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Unless otherwise indicated, the Site is our proprietary property and all
                            source code, databases, functionality, software, website designs, audio,
                            video, text, photographs, and graphics on the Site (collectively, the
                            "Content") and the trademarks, service marks, and logos contained therein
                            (the "Marks") are owned or controlled by us or licensed to us, and are
                            protected by copyright and trademark laws and various other intellectual
                            property rights and unfair competition laws of the United States,
                            international copyright laws, and international conventions. The Content
                            and the Marks are provided on the Site "AS IS" for your information and
                            personal use only. Except as expressly provided in these Terms of Use, no
                            part of the Site and no Content or Marks may be copied,
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            reproduced, aggregated, republished, uploaded, posted, publicly displayed,
                            encoded, translated, transmitted, distributed, sold, licensed, or
                            otherwise exploited for any commercial purpose whatsoever, without our
                            express prior written permission.
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Provided that you are eligible to use the Site, you are granted a limited
                            license to access and use the Site and to download or print a copy of any
                            portion of the Content to which you have properly gained access solely for
                            your personal, non-commercial use. We reserve all rights not expressly
                            granted to you in and to the Site, the Content and the Marks.
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            &nbsp;3.
                        </span>
                        <span
                            style={{
                                fontSize: "13.999999999999998pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            &nbsp;USER REPRESENTATIONS
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            By using the Site, you represent and warrant that: (1) you have the legal
                            capacity and you agree to comply with these Terms of Use; (2) you are not
                            a minor in the jurisdiction in which you reside; (3) you will not access
                            the Site through automated or non-human means, whether through a bot,
                            script, or otherwise; (4) you will not use the Site for any illegal or
                            unauthorized purpose; and (5) your use of the Site will not violate any
                            applicable law or regulation. If you provide any information that is
                            untrue, inaccurate, not current, or incomplete, we have the right to
                            suspend or terminate your account and refuse any and all current or future
                            use of the Site (or any portion thereof).
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "13.999999999999998pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            4. DISCLAIMER
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
                            YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
                            FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
                            IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING,
                            WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                            A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
                            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE'S CONTENT
                            OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
                            LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES
                            OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                            NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3)
                            ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                            PERSONAL INFORMATION AND/OR FINANCIAL
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                            TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR
                            THE LIKE WHICH MAY BE
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY
                            ERRORS OR
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                            KIND
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                            OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
                            GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED
                            OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR
                            ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
                            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
                            FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS
                            OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
                            THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                            JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                        </span>
                    </p>
                    <p>
                        <br />
                    </p>
                    <p
                        dir="ltr"
                        style={{ lineHeight: "1.38", marginTop: "0pt", marginBottom: "0pt" }}
                    >
                        <span
                            style={{
                                fontSize: "13.999999999999998pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            5. CONTACT US
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.7999999999999998",
                            backgroundColor: "#ffffff",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                            padding: "11pt 0pt 0pt 0pt"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Please contact us for any questions or comments (including all inquiries
                            unrelated to copyright infringement) regarding this platform.
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            <br />
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Grievance officer
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.7999999999999998",
                            backgroundColor: "#ffffff",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                            padding: "11pt 0pt 0pt 0pt"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Mr. Yashwant Lohar
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.7999999999999998",
                            backgroundColor: "#ffffff",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                            padding: "11pt 0pt 0pt 0pt"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Address: #1, 5th Cross, 17th &nbsp;Main road, Aicobonagar, near udupi
                            garden park, BTM Stage 1, Bangalore, 560068
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.7999999999999998",
                            backgroundColor: "#ffffff",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                            padding: "11pt 0pt 0pt 0pt"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            contact us
                        </span>
                    </p>
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.7999999999999998",
                            backgroundColor: "#ffffff",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                            padding: "11pt 0pt 0pt 0pt"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Email:&nbsp;
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#000000",
                                backgroundColor: "#ffffff",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Yaswanth@recruitingmonk.com
                        </span>
                    </p>
                    <p>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            Phone: +91 74162 66999&nbsp;
                        </span>
                        <span
                            style={{
                                fontSize: "12pt",
                                fontFamily: "Arial",
                                color: "#212121",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                            }}
                        >
                            <br />
                        </span>
                    </p>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default Terms;