import React from "react";
import styles from "./HeaderCourse.module.css";

const HeaderCourse = () => {
  return (
    <>
      <div
        className={styles.header_container}
        style={{ backgroundImage: `url(/images/headerBG3.jpeg)` }}
      >
        <div className={styles.navbarContainer}>
          <img src="./images/logo.png" alt="" style={{ width: "150px" }} />
          <div className={styles.navbarContents}>
            <div
              className={styles.navbaroption}
              onClick={() => {
                window.location.href = "https://learning.recruitingmonk.com/";
              }}
            >
              Home
            </div>
            <div className={styles.navbaroption}
            style={{ borderBottom: "1.5px solid #191919" }}
            >Mini Diploma</div>
          </div>
        </div>
        <div className={styles.header_container2}>
          <div className={styles.header_heading}>
            Mini Diploma for TA professionals
          </div>
          <div className={styles.header_subHeading}>
            A 12 week intensive learning crafted for experienced TA
            professionals <br />
            in the tech hiring space.
          </div>

          <div className={styles.header_course_details}>
            <div className={styles.course_date}>
              <div className={styles.courseDetails1}>
                <div className={styles.course_info}>Application deadline</div>
                <div className={styles.course_subInfo}>5th November 2024</div>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.courseDetails2}>
                <div className={styles.course_info}>Course starts on</div>
                <div className={styles.course_subInfo}>9th November 2024</div>
              </div>
            </div>
          </div>

          <div
            className={styles.apply_button}
            onClick={() => window.open("https://wa.me/+917416266999", "_blank")}
          >
            Apply Now
          </div>

          <div className={styles.apply_Message}>
            *The cohort is limited per Batch.
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderCourse;
